.c-upload {
    border: 1px dashed $field-border-focus;
    padding-bottom:20px;
    padding-top:30px;
    text-align: center;
    position: relative;
}
.c-upload__input {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    opacity: 0;
    &:hover {
        cursor: pointer;
    }
}
.c-upload__label {
    @extend .c-field__label;
    margin-top:46px;
    margin-bottom:40px;
}