.c-attribute-list {

}

.c-attribute-list__label {
    color: $primary-blue;
    font-size: rem(24px);
    margin-block: rem(4px);
    margin-top: rem(32px);

    &:first-child {
        margin-top: 0;
    }
}

.c-attribute-list__value {
    font-size: rem(20px);
}