﻿.c-meeting-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $primary-blue;
    margin-bottom: 50px;

    @include breakpoint($md) {
        margin-bottom: 72px;
    }

    &__block {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        color: $primary-blue;

        &:not(:last-child) {
            margin-bottom: 27px;

            @include breakpoint($md) {
                margin-bottom: 48px;
            }
        }

        &:hover .c-meeting-item__subheading {
            color: $lime-scooter;
        }
    }
}

.c-meeting-item__heading {
    font-size: 24px;
    line-height: 36px;
    color: inherit;
    margin-bottom: 24px;

    @include breakpoint($md) {
        margin-bottom: 30px;
        font-size: 32px;
        line-height: 50px;
    }
}

.c-meeting-item__subheading {
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    margin-bottom: 8px;

    @include breakpoint($md) {
        font-size: 24px;
        line-height: 23px;
        margin-bottom: 12px;
    }
}

.c-meeting-item__copy {
    p {
        font-size: 12px;
        line-height: 18px;

        b {
            font-weight: 700;
        }

        @include breakpoint($md) {
            font-size: 16px;
            line-height: 23px;
        }
    }
}