/**
 * UTILITIES.SPACING
 *
 * @description : Quickly apply margins/paddings where needed.
 *
 * Feel free to change these pixel values to whatever you need.
 * Pixel numbers don't need to match the ordinal numbers.
 */

//////        0    1    2    3     4     5     6     7     8     9     10    11     12
$spacings-px: 0px, 4px, 8px, 16px, 24px, 32px, 48px, 56px, 64px, 80px, 96px, 128px, 160px;

$properties: padding, margin;
$directions: top, right, bottom, left;

@for $i from 1 through length($spacings-px) {
    $number: $i - 1;
    $spacing-px: nth($spacings-px, $i);

    @each $prop in $properties {
        @each $dir in $directions {
            $name-base: str-slice($prop,0,1) + str-slice($dir,0,1) + '-' + $number;

            .u-#{$name-base} {
                $prop-name: $prop + '-' + $dir;
                #{$prop-name}: rem($spacing-px) !important;
            }
        }
    }
}

@for $i from 1 through length($spacings-px) {
    $number: $i - 1;
    $spacing-px: nth($spacings-px, $i);

    @each $prop in $properties {
        @each $dir in $directions {
            $name-base: str-slice($prop,0,1) + str-slice($dir,0,1) + '-' + $number;

            .u-mob-#{$name-base} {
                @media (max-width: $md) {
                    $prop-name: $prop + '-' + $dir;
                    #{$prop-name}: rem($spacing-px) !important;
                }
            }
        }
    }
}