.c-fees-table {
    background: $offwhite-translucent;
    padding: 10px 16px;

    @include breakpoint($md) {
        padding: 30px 40px;
    }

    thead {
        background: $offwhite;
        position: sticky;
    }

    @media (max-width: $sm) {
        width: 100vw !important;
        left: calc(-4px + 1.16666667%);
    }
}

.c-fees-table {
    position: absolute;

    th {
        border-bottom: 1px solid rgba(0, 57, 91, 0.2);
        border-top: none;
        font-weight: 600;
        padding: 24px 16px;
    }
}

.c-fees-table__cell {
    border-right: 1px solid rgba(0, 57, 91, 0.2);
    padding: 12px 16px;
    text-align: left !important;

    &:nth-child(2) {
        border-right: none;
    }
}

.c-fees-table__row {
    border-bottom: 1px solid rgba(0, 57, 91, 0.2);
    border-top: none;

    &:last-child {
        border-bottom: none;
    }
}