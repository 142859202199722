.c-detail-list__title {
    color:$listing-text-colour;
    margin-top:16px;
    margin-left:16px;

    .c-listing__full--page & {
        margin-top:0;
    }
    @include breakpoint($md) {
        margin-left:0;
    }
}
.c-detail-list__icon {
    margin: auto;
}
.c-detail-list__detail {
    color:$listing-text-colour;
    font-size: 0.725em;
    margin-left:16px;
    @include breakpoint($md) {
        margin-left:0;
    }
}
.c-detail-list__share {
    display: flex;
    text-align: center;
    color:$sharing-links-label-colour;
    font-weight: 100;
    text-decoration: none;
    font-size:1em;
    text-transform: uppercase;
    max-width: 170px;
    margin-top:32px;

    
    .c-listing__full--page & {
        max-width:102px;
        @include breakpoint($md) {
            max-width:80px;
        }
    }
}