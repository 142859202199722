﻿.c-video-thumb {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .c-video-thumb__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgba(0,0,0,0.3);
        }

        .c-video-thumb__button-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
        }
    }

    .c-video-thumb__title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        //background-color: $color-black-80;
        //color: $color-white;
        font-size: 14px;
        line-height: 1.4;
        transition: background-color 0.3s ease;
    }

    .c-video-thumb__aspect {
        aspect-ratio: 16 / 9;
    }
}
