.c-checkbox {
    
}
.c-checkbox__input {
    // visually hidden
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.c-checkbox__label {
    float: none !important;
    width:100%;
    font-weight: 300;
    padding: 12px 12px 12px 0;

    .c-dropdown & {
        padding:21px;
        font-size: 1.063rem;
    }
    .c-dropdown--narrow & {        
        padding:8px;
        font-size: 0.7em;
    }
    
    &:before {
        content:"";
        display: inline-block;
        border: 1px solid $checkbox-border;
        border-radius: 2px;
        background-color: $checkbox-bg;
        height:16px;
        width:16px;
        margin-right:10px;
        transition: background 0.25s ease;
    }
    .c-checkbox__input:checked + & {
        &:before {
            background:$checkbox-checked-bg;
            background-image: url("/Image/icons/checkmark.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;
        }
    }
    &:hover {
        cursor: pointer;
    }
}