

.c-rr__search-bar {
    display: flex;
}

.c-rr__search-box {
    flex: 1;
    position: relative;

    &--active {

        &:after {
            content: "";
            left: 0;
            height: 100vh;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 10;
        }
    }
}

.c-rr__search-input {
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    color: $text-color !important;
    height: 100%;
    padding: rem(20) rem(28);
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;
    z-index:11;

    &:hover {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.25);
        transition: box-shadow 0.2s ease-in-out;
    }

    &--active {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.c-rr__search-button {
    border-radius: 5px;
    background-color: $lime-scooter;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    cursor: pointer;
    margin-left: rem(20);
    padding: rem(20);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.25);
        transition: box-shadow 0.2s ease-in-out;
    }
}

.c-rr__search-input-results {
    background-color: $primary-bg;
    border: 1px solid $silver;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    z-index: 11;

    &--open {
        display: flex;
    }
}

.c-rr__search-input-result {
    cursor: pointer;
    display: none;
    padding: rem(10) rem(28);
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
        background-color: $lime-scooter;
        color: $white;
        transition: all 0.2s ease-in-out;
    }

    &--active {
        display: initial;
    }
}

.c-rr__search-letter-selector {
    border-bottom: 1px solid $border-blue;
    margin-bottom: rem(50);
    padding-bottom: rem(40);
}

.c-rr__search-title {
    color: $primary-blue;
}

.c-rr__search-letters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(14);

    @include breakpoint($md) {
        justify-content: space-between;
    }
}

.c-rr__search-letter {
    color: $tasman-mako;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &--selected, &:hover {
        color: $lime-scooter;
        transition: color 0.2s ease-in-out;
    }
}

.c-rr__letter-filter {
    max-height: 0;
    margin-bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease-in-out;

    &--show {
        max-height: fit-content;
        margin-bottom: rem(68);
        opacity: 1;
        pointer-events: auto;
        transition: all 0.5s ease-in-out;
    }
}

.c-rr__letter-selected {
    color: $primary-blue;
    font-size: 42px;
    line-height: 42px;
    padding-bottom: rem(40);
}

.c-rr__filter-list {
    display: flex;
    flex-direction: column;
}

.c-rr__filter-item {
    border-bottom: 1px solid $border-blue;
    color: $tasman-mako;
    cursor: pointer;
    display: none;
    font-size: 20px;
    line-height: 31px;
    padding: rem(10) 0;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $lime-scooter;
        transition: color 0.2s ease-in-out;
    }

    &--active {
        display: initial;
    }
}

.c-rr__item {
    display: none;
    margin-bottom: rem(68);
    padding-bottom: rem(90);

    &--active {
        display: block;
    }
}

.c-rr__item-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -65px;
    padding-top: 65px;

    @include breakpoint($md) {
        flex-direction: row;
        margin-top: -120px;
        padding-top: 120px;
    }
}

.c-rr__item-wrap {
    flex: 1;
    margin-top: rem(32);

    @include breakpoint($md) {
        margin-top: 0;
    }
}

.c-rr__item-title {
    color: $primary-blue;
    font-size: 42px;
    line-height: 42px;
    padding-bottom: rem(40);
}

.c-rr__item-content {
}

.c-rr__item-detail {
    display: flex;

    @include breakpoint($md) {
        margin-bottom: rem(30);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.c-rr__item-detail-content {
    margin-left: rem(17);
}

.c-rr__detail-title {
    color: $tasman-mako;
    font-size: 24px;
    font-weight: bold;
}

.c-rr__detail-description {
    color: $text-color;
    font-size: 21px;
}

.c-rr__item-key {
    /*border: 2px solid #000000;
    border-radius: 50%;*/
    height: 40px;
    margin-top: 4px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;

    .c-rr__key & {
        height: 30px;
        margin-top: 0;
        min-height: 30px;
        min-width: 30px;
        width: 30px;

        @include breakpoint($md) {
            height: 40px;
            margin-top: 0;
            min-height: 40px;
            min-width: 40px;
            width: 40px;
        }
    }
    /*&--blue {
        border-color: $med-blue;
    }

    &--black {
        border-color: #000000;
    }

    &--red {
        border-color: $regulatory-red;
    }

    &--yellow {
        border-color: $mustard;
    }*/
}

.c-rr__key {
    background-color: #ffffff;
    top: 64px;

    @include breakpoint($md) {
        margin-left: rem(30);
        position: initial;
        top: initial;
    }
}

.c-rr__key-inner {
    background-color: rgba(209, 218, 225, 0.1);
    padding: rem(18) rem(12) rem(24) rem(12);

    @include breakpoint($md) {
        padding: rem(24) rem(18) rem(36) rem(18);
        position: -webkit-sticky;
        position: sticky;
        top: 120px;
    }
}

.c-rr__key-title {
    color: $tasman-mako;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: rem(20);

    @include breakpoint($md) {
        margin-bottom: rem(30);
        font-size: 21px;
        line-height: 28px;
    }
}

.c-rr__key-details {
    /*columns: 2;*/

    @include breakpoint($md) {
        display: flex;
        flex-direction: column;
    }
}

.c-rr__key-detail {
    align-items: center;
    display: flex;
    margin-bottom: rem(20);

    @include breakpoint($md) {
        margin-bottom: rem(12);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.c-rr__key-detail-desc {
    color: $text-color;
    font-size: 14px;
    line-height: 16px;
    margin-left: rem(10);

    @include breakpoint($md) {
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
    }
}

.c-rr__extra-info {
    margin-bottom: rem(40);
    margin-top: rem(60);
}

.c-rr__extra-info-title {
    color: $primary-blue;
    font-size: 42px;
    line-height: 40px;
    margin-bottom: rem(40);
}

.c-rr__extra-info-description {
    color: $text-color;
    font-size: 21px;
    line-height: 31px;
    margin-bottom: rem(20);
}

.c-rr__extra-info-link {
    margin-bottom: rem(10);
}

.c-rr__title1 {
    color: $primary-blue;
    font-size: 36px;
    line-height: 40px;

    @include breakpoint($md) {
        font-size: 42px;
        line-height: 42px;
    }
}

.c-rr__title2 {
    color: $primary-blue;
    font-size: 30px;
    line-height: 40px;

    @include breakpoint($md) {
        font-size: 42px;
        line-height: 42px;
    }
}

.c-rr__modules {

    &--hide {
        display: none;
    }
}

.c-rr__base-content {
    margin-bottom: rem(70);

    @include breakpoint($md) {
        margin-bottom: rem(160);
    }
}
