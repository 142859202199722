/**
 * COMPONENTS.HEADER
 *
 * @description : Main site header
 *
 */

.c-header {
    z-index: $header-z;
    // position: relative;
    width: 100%;
    left: 0;
    right: 0;
    background: $tasman-mako;
    padding: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.5);

    @include breakpoint($menu) {
        border-bottom: none;
        padding: 0 20px;
        position: relative;

        .g {
            border-bottom: 1px solid rgba($old-green, 1);
            transition: border-bottom 0.2s ease;
        }
    }

    @include breakpoint($xl) {
        padding: 0;
    }
}
.c-header--scrolled {
    position: sticky;
    top: 0;

    .g {
        border-bottom: 1px solid rgba($lime-scooter, 0);
    }
}
.c-header__logo {
    height:30px;
    width:118px;
    
    @include breakpoint($menu) {
        height:60px;
        width:200px;

        position: absolute;   
        top: 32px;
        transition: transform 0.5s ease-out;
        
        .c-header--scrolled & {
            //transform: scale(0.9) translateY(-16px) translateX(-10px);
        }
    }
}
.c-header__list {
    text-align: right;
    transition: height .5s ease-out, opacity .25s ease-out;
    height: 69px;

    .c-header--scrolled & {
        //height: 32px;
        //opacity: 0;
    }
}
.c-header__sublinks {
    display: none;
    @include breakpoint($menu) {
        margin-right:28px;
        display: block;
    }
}
.c-header__list-item {
    display: inline-block;
    margin-right: 40px;

    &:last-child {
        margin-right: 0px;
    }
}
.c-header__link {
    color: $white;
    font-size: 13px;
    padding:30px 0 20px;
    display: block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.c-header__link-icon {
    vertical-align: text-bottom;
    font-size: 15px;
    margin-right:3px;
}
.c-header__hr {
    margin:0;
    width:100%;
    border-top: 1px solid $lime-scooter;
    display: none;
    
    @include breakpoint($menu) {
        display: block;
    }
}