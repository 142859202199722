.js-slider__cta-slide {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    .swiper-wrapper {
        overflow: visible;
    }

    .swiper-pagination-bullets {
        bottom: 0;

        @media (max-width: $md) {
            bottom: 40px;
        }
    }

    .swiper-pagination-bullet {
        display: inline-block;
        border-radius: 0;
        width: 120px;
        height: 6px;
        background: #000;
        opacity: 0.2;
        margin: 0 4px;


        &.swiper-pagination-bullet-active {
            background-color: $lime-scooter;
            opacity: 1;
        }

        @media (max-width: $md) {
            width: 80px;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: calc(50% - 2.5rem);
        color: $dark-blue;
        top:33%;


        .swiper-button-next-content {
            font-family: swiper-icons;
            font-size: 44px;
       
        }

        .swiper-button-prev-content {
            font-family: swiper-icons;
            font-size: 44px;
      
        }

        @media (max-width: $md) {
            display: none;
        }
    }

    .swiper-button-prev {
        left: 0;

        ::after {
            content: 'prev';
        }

        @media (max-width: $lg) {
            left: 10px;
        }
    }

    .swiper-button-next {
        right: 0;

        ::after {
            content: 'next';
        }


        @media (max-width: $lg) {
            right: 10px;
        }
    }

    @media (max-width: $lg) {
        margin: 0 auto;
        max-width: calc(100% - 36px);
    }
}

.c-cta-carousel__container {
    position: relative;
    padding-top: 40%;

    @media (max-width: $lg) {
        padding-top: 0;
    }
}

.c-cta-carousel__font_color--white {
    color:white !important;
}

.c-cta-carousel__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 5rem;
    background-clip: content-box;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: $lg) {
        flex-direction: column-reverse;
        position: relative;
    }
}

.c-cta-carousel__content-wrap {
    flex: 1;
    flex-basis: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $lg) {
        padding: 3rem 1rem !important;
    }
}

.c-cta-carousel__content-heading-wrap,
.c-cta-carousel__content-desc-wrap,
.c-cta-carousel__content-cta-wrap {
    padding-left: 15%;
    width: calc(100% - 30%);

    @media (max-width: $lg) {
        width: 100%;
        padding: 0rem !important;
    }
}

.c-cta-carousel__content-cta-wrap {
    display: flex;
    justify-content: flex-start;

    @media (max-width: $lg) {
        justify-content: center;
    }
}

.c-cta-carousel__content-heading {
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 20px;
    text-align: left;

    @media (max-width: $lg) {
        text-align: center;
        font-size: 36px;
        line-height: 36px;
    }
}

.c-cta-carousel__content-desc {
    color: $black;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;

    @media (max-width: $lg) {
        text-align: center;
    }
}

.c-cta-carousel__content-cta {
    border-color: $tasman-mako;
    color: $tasman-mako;
    transition: all .2s;

    &:hover {
        background-color: $tasman-mako;
        border-color: $tasman-mako;
        color: $white;
    }
}

.c-cta-carousel__image-wrap {
    flex: 1;
    flex-basis: 0px;
    //overflow: hidden;
    position: relative;

    @media (max-width: $lg) {
        overflow: visible;
    }
}

.c-cta-carousel__image {
    width: 100%;
    height: 100%;
}