.c-radio {
    .c-timetable__filters & {
        display: inline-block;
    }
}
.c-radio__input {
    // visually hidden
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.c-radio__label {
    font-family: $main-font;
    width: 100%;
    font-weight: 300;
    padding: 12px 12px 12px 0;
    position: relative;
    font-size: .9em;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
    }

    .c-dropdown & {
        padding: 21px;
    }

    &:before {
        content: "";
        display: inline-block;
        border-radius: 100%;
        box-shadow: inset 0 0 0 1px $mid-grey, inset 0 0 0 9px #fff;
        background-color: $sky-blue;
        height: 17px;
        width: 17px;
        vertical-align: -2px;
        margin-right: 10px;
        transition: 0.2s;
    }

    .c-radio__input:checked + & {
        &:before {
            box-shadow: inset 0 0 0 2px $sky-blue, inset 0 0 0 4px #fff;
        }

        .c-timetable__filters & {
            &:before {
                border-color: $timetable-active-color;
            }
        }
    }
}