.o-map {
    height: 340px;
    max-width: 100%;
    
    @include breakpoint($md){
        margin-left: 60px;
    }
}
.o-map__link {
    @include breakpoint($md){
        margin-left: 60px;
    }
}