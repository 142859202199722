/**
 * COMPONENTS.KEYWORD-SEARCH
 *
 * @description :
 *
 */
.c-keyword-search {
    position: relative;
}

.c-keyword-search__input {
    font-size: 1.04762rem !important;
    height: 50px;
    padding-left: 15px !important;
}

.c-keyword-search__label {
    bottom: 0;
    color: $grey-60;
    display: block;
    font-size: 1.04762rem;
    font-weight: 300;
    left: 0;
    line-height: 50px;
    padding: 0 15px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    .c-keyword-search__input:valid ~ &,
    .c-keyword-search__input:focus ~ & {
        display: none;
    }
}