/**
 * COMPONENTS.BREADCRUMBS
 *
 * @description :
 *
 */

$bp-breadcrumbs : 769px;

.c-breadcrumbs {
    display: flex;
    align-items: center;
}

.c-breadcrumbs__list {
    display: none;

    @include breakpoint($bp-breadcrumbs) {
        display: flex;
        margin-right: auto;
        align-items: center;
    }
}

.c-breadcrumbs__item {
    display: flex; // Hide all items by defalut
    color: $breadcrumbs-colour;
    margin-right: 1rem;
    align-items: center;
    font-size:rem(18);
}

.c-breadcrumbs__item--back {
    &::before {
        content: '';
        display: block;
        width: 0.6em;
        height: 0.9em;
        background-size: cover;
        margin-right: 1rem;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9"><polygon fill="rgba(0,0,0,0.3)" points="5.163 7.188 2.475 4.5 5.163 1.826 4.337 1 .837 4.5 4.337 8"/></svg>');
    }

    @include breakpoint($bp-breadcrumbs) {
        display: none;
    }
}

.c-breadcrumbs__item--ancestor {
    &::after {
        content: '';
        display: block;
        width: 0.6em;
        height: 0.9em;
        background-size: cover;
        margin-left: 1rem;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9"><polygon fill="rgba(0,0,0,0.3)" points=".837 7.188 3.525 4.5 .837 1.826 1.663 1 5.163 4.5 1.663 8"/></svg>');
    }
}

.c-breadcrumbs__item--current {
    font-weight: bold;
}

.c-breadcrumbs__link {
    align-items: center;
    color: inherit;
    display: flex;
    transition: color 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
        color: $beta;
    }
}
