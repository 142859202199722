﻿
.c-hero {
    width: 100%;
    padding-top: 380px;
    position: relative;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    //margin-bottom: 7rem;

    @media (max-width: $lg) {
        padding-top: 15%;
    }
}

.c-hero__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // padding-bottom: 5rem;

    @media (max-width: $lg) {
        position: relative;
        padding: 15px;
    }
}

.c-hero__heading {
    color: $white;
    font-size: 90px;
    line-height: 90px;
    font-weight: bold;

    @media (max-width: $md) {
        font-size: 60px;
        line-height: 60px;
    }
}

.c-hero__subheading {
    color: $white;
    font-size: 36px;
    font-weight: bold;

    @media (max-width: $md) {
        font-size: 26px;
    }
}

.c-hero__links {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: rem(20);
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
}

.c-hero__link {
    color: $white;
    font-size: 1.15rem;
    transition: color .2s;

    &:hover {
        color: $lime-scooter;
    }
}

.c-hero__search-bar {
    background-color: transparent !important;

    .c-header-wrapper--search-bar-hidden & {
        max-height: unset !important;
    }

    .c-large-input__input::placeholder {
        color: $concrete !important;
    }

    .c-hero__submit {
        min-width: unset !important;
        background-color: $old-green !important;
        margin-left: 10px !important;
        padding: 1em 1.5em !important;

        &:hover {
            background-color: $regular-green !important;
        }

        svg {
            left: 50% !important;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: $lg) {
                position: absolute;
                /*right: 10px !important;
                top: 12px;*/
                margin: 0px !important;
            }
        }

        @media (max-width: $lg) {
            max-width: 65px;
            min-width: 65px !important;
        }
    }
}

.c-hero__cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2rem;
    margin-bottom: -5rem;

    .c-menu__card_image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .c-menu__feature {
        padding-bottom: 0 !important;
        margin-bottom: -4rem;

        @media (max-width: $lg) {
            margin-bottom: 0rem;
        }
    }

    .c-menu__card_content {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
    }

    .c-menu__features {
        padding-top: 40px;
        flex: 0 1 43%;

        @media (max-width: $lg) {
            padding-top: rem(0);
            justify-content: center;
        }
    }

    .c-menu__card_heading {
        text-transform: none;
        margin-top: 10px;
    }

    @media (max-width: $lg) {
        flex-direction: column;
        margin-bottom: 0;
        padding-top: rem(25);
        padding-bottom: rem(15);
    }
}


/* Section landing page Hero styles */

.c-section-hero {
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-top: 300px;
    margin-top: 0;
    margin-bottom: 80px;

    @media (max-width: $md) {
        align-items: center;
        display: flex;
        height: 100vw;
        max-height: 300px;
        justify-content: center;
        text-align: center;
        padding-top: 0;
    }
}

.c-section-hero__content {

    @media (min-width: $md) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @media (max-width: $md) {
        height: 100%;
        width: 100%;
    }
}

.c-section-hero__block {
    @media (max-width: $md) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.c-section-hero__content h1 {
    color: $white;
    font-weight: bold;
    font-size: 90px;
    line-height: 98px;

    @media (max-width: $md) {
        font-size: 45px;
        line-height: 49px;
    }
}

.c-section-hero__content h3 {
    color: #FFF;
    font-weight: 100;
    font-size: 27px;
    line-height: 37px;
}

.c-section-hero__toolbar {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;

    @media (max-width: $md) {
        bottom: 0;
        right: unset;
        width: 100%;
        justify-content: center;
    }

    a {
        color: $white;

        svg path {
            //stroke: $white !important;
        }
    }
}

.c-section-hero__social-links {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding-bottom: 0px;

    @media (max-width: $md) {
        margin-bottom: 8px;
    }
}

.c-section-hero__social-link {
    color: #fff;
    display: inline-block;
    font-size: 0.65em;
    text-decoration: none;
    margin-bottom: 10px;
    flex: 1;
    max-width: 70px;
    margin-right: 20px;

    svg {
        margin-right: 0;
        transition: transform .2s;
    }

    @media (max-width: $lg) {
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover svg {
        transform: scale(1.1);
    }
}