.c-large-input {
    //background: $tasman-mako;
    // border-top: solid $tasman-mako 71px; //header height
    
    // border-top: solid $tasman-mako 148px; //header height
    .c-header--scrolled + & {
        padding-top:71px;
        @include breakpoint($menu) {
            padding-top:148px;
        }
    }
}
.c-large-input__input { 
    &::placeholder {
        color:$sport-blue !important;
    }
    @include breakpoint($sm){
        width:100%;
    }
    @include breakpoint($md) {
        //font-style: italic;
        
        &::placeholder {
            color:$field-placeholder-color !important;
        }
    }
    
    &input[type="text"] {
        width:calc(100% - 42px);
    }
}
.c-large-input--large { 
    background: linear-gradient(0deg, #0084C5 0%, #00355F 100%);
    padding-bottom: 50px;

    margin-bottom: 52px;
    @include breakpoint($md) {
        margin-bottom: 115px;
    }
}
.c-large-input__bar {
    margin: 16px;
    @include breakpoint($sm) {
        margin: 20px;
    }
    @include breakpoint($md) {
        margin: 30px;
    }
}
.c-large-input__input--newsletter {
    font-size: 16px !important;
    line-height: 16px;
    box-shadow: 0 2px 10px 0 $black-20;

    &::placeholder {
        color:$concrete !important;
    }
}
.c-large-input__form {
    display: flex;
    width: 100%;
    height:65px;
    font-size: 1em;
}
.c-large-input__form--header {
    height: 65px;
    font-size: 0.8em;
    margin-inline: 16px;

    @include breakpoint($md) {
        font-size: 1em;
        height: 65px;
        margin-inline: 0;
    }
}
.c-large-input__title {
    color: $white;
    text-align: center;
    
    font-size: 30px;
    margin-bottom: 20px;
    @include breakpoint($md) {
        font-size: 55px;
        margin-bottom: 30px;
    }
}
.c-large-input__submit {
    padding:10px;
    margin-left:10px;
    background:$old-green;
    min-width: 65px;
    
    i {
        margin-left:0;
    }
    
    @include breakpoint($sm) {
        margin-left:20px;
    }
    @include breakpoint($md) {
        margin-left:30px;
        padding:1em 2em;
        min-width: 220px;

        i {
            margin-left:32px;
        }
    }
}
.c-large-input__submit--newsletter {
    margin-left: 0;
    @include breakpoint($md){
        margin-left: 30px;
    }
}
.c-large-input__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30px;
    // height:38px;

    @include breakpoint($md){
        display: none;
    }
}
.c-large-input__search-icon {
    width: 30px;
    height: 30px;
    margin: 0;

    @include breakpoint($sm) {
        margin: 0 18px 0 10px;
    }

    @include breakpoint($md) {
        margin: 0;
        position: absolute;
        right: 40px;
        top: 16px;
    }

    .c-large-input__form--header & {
        height: 22px;

        @include breakpoint($md) {
            height: 30px;
        }
    }
}