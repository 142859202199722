.c-alerts {
    position: sticky;
    border-bottom:13px solid $alert-text-color;
    z-index: 9999;
    top: 0;
    background-color: #FFF;
    
    p {
        color: $alert-text-color;
        line-height: 1.3;
        font-weight: 500;
    }
}
.c-alerts--dismissed {
    display: none;
}
.c-alert {
    padding:16px 20px;
    
    @include breakpoint($xl) {
        padding:16px 0;
    }
}
.c-alert__close-btn {
    background: transparent;
    padding-top: 6px;

    &:hover {
        cursor: pointer;
    }
}
.c-alert__icon {
    height:32px;
    width:32px;
    display: none;
    margin-right:16px;
    vertical-align: top;
    margin-top: 6px;

    @include breakpoint($md){
        display: inline-block;
    }
}
.c-alert--dismissed {
    display: none;
}
.c-alert__copy {
    display: inline-block;
    width: calc(100% - 16px);

    @include breakpoint($md){
        width: calc(100% - 70px);
    }
}