@font-face {
    font-family: 'Aller';
    font-display: fallback;
    src:url("../fonts/aller/Aller_W_Rg.eot?#iefix");
    src:url("../fonts/aller/Aller_W_Rg.eot?#iefix") format("eot"), url('../fonts/aller/Aller_W_Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aller';
    font-display: fallback;
    src:url("../fonts/aller/Aller_W_Lt.eot?#iefix");
    src:url("../fonts/aller/Aller_W_Lt.eot?#iefix") format("eot"), url('../fonts/aller/Aller_W_Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
