/**
 * COMPONENTS.BUTTONS
 *
 * @description : Main buttons
 *
 */

// Base button styles
.c-btn {
    vertical-align: middle;
    text-decoration: none;
    transition: background-color 0.2s ease;
    font-family: $main-font;
    font-style: 1em;
    position: relative;
    display: block;


    @include breakpoint($sm){
        display: inline-block;
    }

    &:hover {
        cursor: pointer;
    }

    .c-link-list & {
        margin-bottom: 22px;
        @include breakpoint($md) {
            margin-right: 16px;
        }
    }
    .c-link-list--vertical & {
        display: block;
    }
}

// Primary button
.c-btn--pri {
    background: $alpha;
    color: white;
    font-size: 1em;
    padding: 0.6em 1.5em;
    border-radius: 5px;
    background-color: $environment-green;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    transition: background-color 0.25s ease, box-shadow 0.25s ease;

    &:hover {
        background-color: $financial-green;
        box-shadow: none;
    }

    .c-link-list & {
        vertical-align: top;
    }
}

.c-btn__share {
    padding:.8em 1em;
    margin:0 16px;
    display: flex;

    @include breakpoint($sm){
        padding:.8em 1.5em;
    }
    @include breakpoint($md){
        display: inline-flex;
    }

    &:hover {
        background-color: $environment-green;
    }
}
.c-btn__share-link {
    display: inline-block;
    width:24px;
    height:24px;
    margin-right:16px;
    vertical-align: -4px;
    transform-origin: center;

    @include breakpoint($md){
        opacity: 0;
        transform: scale(0);
    }

    &:hover {
        vertical-align: -3px;
    }

    .c-btn__share:hover & {
        opacity: 1;
        transform: scale(1);
    }
    &:nth-child(4){
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.25s ease-in 0.15s;
    }
    &:nth-child(3){
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.25s ease-in 0.25s;
    }
    &:nth-child(2){
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.25s ease-in 0.4s;
    }
    &:nth-child(1){
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.25s ease-in 0.5s;
    }
}
.c-btn__share-links {
    border-radius: 5px;
    white-space: nowrap;
    right:85px;
    padding:0;
    background: $environment-green;
    overflow: hidden;
    transition: max-width 0.35s ease-in;
    top:0;
    padding:0 0.8em 0 0.7em;
    display: inline;

    @include breakpoint($sm){
        padding:0.8em 0.3em .8em 1.5em;
    }
    @include breakpoint($md){
        max-width: 0;
        display: inline-block;
        width:auto;
        position: absolute;
    }

    .c-btn__share:hover & {
        max-width:230px;
    }
}
// Secondary button
.c-btn--sec {
    color:$primary-blue;
    padding: 1em 1.5em;
    border-radius: 5px;
    border: 1px solid $primary-blue;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    transition: border-color 0.25s ease, color 0.25s ease;

    &:hover {
        border-color: $financial-green;
        color:$financial-green;
    }
}

.c-btn--blue {
    color:white;
    background-color: #008acc;
    padding: 1em 1.5em;
    border-radius: 5px;
    border: 1px solid #008acc;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    transition: border-color 0.25s ease, color 0.25s ease;

    &:hover {
        border-color: $primary-blue;
        background: $primary-blue;
        color:white;
    }
}

.c-btn--homepage {
    height:52px;
    padding:0.5em 1.5em;
}
.c-btn--icon {
    height: 65px;
    display: inline-flex;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: .665em 1em;

    &--only {
        justify-content: center;

        img {
            margin-left: 0 !important;
        }
    }

    i {
        display: inline-block;
        vertical-align: text-bottom;
        margin-left: 32px;
        font-size: 1.2em;
        float: right;

        @include breakpoint($md) {
            float: none;
            font-size: 1.5em;
        }
    }

    img {
        display: inline-block;
        height: 40px;
        vertical-align: -11px;
        margin-left: 20px;
        /*width:30px;*/
        float: right;

        @include breakpoint($md) {
            float: none;
            width: auto;
        }
    }

    @include breakpoint($md) {
        padding: .665em 2em;
        width: fit-content;
    }
}
.c-btn--external {
    font-size: rem(24);
    padding:16px 0px;

    img {
        height:24px;
        vertical-align: -8px;
        margin-left: 8px;
        position: relative;
        float: none;
        vertical-align: 0;
    }

    @include breakpoint($md){
        padding:0.665em 3.3em;

        img {
            height:28px;
            vertical-align: -8px;
            float: right;
        }
    }
}
.c-btn--long {
    font-size: rem(18);
    @include breakpoint($sm){
        font-size: 1em;
    }
}
.c-btn--wide {
    text-align:center;
    @include breakpoint($md) {
        padding: 1em 3em;
    }
}
.c-btn--link {
    color: $primary-blue;
    padding-right: 60px;
    font-size: 0.9em;
    width: 100%;

    &.c-btn--pri {
        color: $white;
        margin-bottom: 16px;
        margin-right: 16px;
        padding: 0.61em 1.5em;
    }

    .c-link-list--vertical & {
        color: $environment-green;
    }

    &.c-btn--link-btn {
        color: $white;
        display: inline-block;
    }

    @include breakpoint($md) {
        width: auto;
        font-size: 0.9em;
    }
}

.c-btn--prev-link,
.c-btn--next-link {
    color: $primary-blue;
    font-size: 0.9em;
}

.c-btn--flex {
    display: flex !important;
    gap: rem(5px);

    & > *:not(svg) {
        padding-top: rem(8px);
    }
}

.c-btn--inline-flex {
    display: inline-flex !important;
    gap: rem(5px);

    & > *:not(svg) {
        padding-top: rem(8px);
    }
}

.c-btn--link-internal {
    color:$environment-green;

    .c-link-list & {
        margin-bottom: 0;
    }
}
.c-btn__details {
    display: block;

    @include breakpoint($md) {
        display: inline-block;
        font-weight: 300;
    }

    .c-btn--pri & {
        font-size: 0.7em;
    }

    .c-link-list--vertical & {
        color: $environment-green;
        font-weight: 500;
    }

    .c-link-list--vertical--alt & {
        color: $environment-green;
        font-weight: 500;
    }

    .c-btn--link-btn & {
        color: $white;
    }
}
.c-btn__icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
    position: absolute;
    right: 0;
    top: 7px;
    width: 35px;
    height: 35px;
    overflow: visible;

    .c-btn--link-internal & {
        transform: rotate(-90deg);
    }

    .c-link-list--vertical & {
        margin: 0 12px 0 0;
    }

    .c-link-list--vertical--alt & {
        margin: 0 12px 0 0;
    }

    .c-btn--pri & {
        right: 1.5em;
        top: 12px;

        @include breakpoint($md) {
            right: auto;
            top: auto;
        }
    }

    @include breakpoint($md) {
        position: relative;
        top: auto;
        width: 42px;
        height: 42px;
    }
}
// Secondary button
.c-btn--sm {
}

.c-btn--large {
    display: block;
    background: $white;
    margin: 30px auto;
    font-size: 1em;

    .c-upload & {
        margin:0 auto 50px auto;
    }
}

.c-btn--filter {
    width:100%;
    font-size: 1em;
    padding: .55em 1.5em;
    max-height:50px;
    margin-bottom: 46px;

    @include breakpoint($md) {
        margin-bottom: 0px;
    }
}

.c-btn__faux-link {
    font-size:0.938rem;
    font-family: $secondary-font;
    color: $card-link-color;
    font-weight: 500;

    &::after {
        border-style: solid;
        border-width: 0.05em 0.05em 0 0;
        content: '';
        color:$card-chevron-color;
        display: inline-block;
        height: 0.6em;
        left: 0.6em;
        position: relative;
        top: 0.6em;
        transform: rotate(45deg);
        vertical-align: top;
        width: 0.65em;
    }
}
.c-btn__back-to-top-container {
    max-width: $bodyMax;
    position: fixed;
    position: sticky;
    bottom: 100px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: $back-to-top-z;
    text-align: right;
    height:62px;
    pointer-events: none;
}
.c-btn--error-page {
    margin-top:10px;
}
.c-btn__back-to-top {
    opacity: 0;
    transition: opacity 0.35s ease, background 0.35s ease;
    width:52px;
    height:52px;
    vertical-align: top;
    display: inline-block;
    background:url("/Image/icons/back-to-top.svg");
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: all;
    transform:translateY(30px);
    margin-right:25px;

    @include breakpoint($lg){
        margin-right:0px;
    }

    @include breakpoint($md){
        width:62px;
        height:62px;
    }

    &:hover {
        cursor: pointer;
        background:url("/Image/icons/back-to-top-hover.svg");
    }
    .c-btn__back-to-top--scrolled & {
        opacity: 1;
    }
    .c-btn__back-to-top--static & {
        margin-bottom: -71px;
    }
}
.c-btn__back-to-top--static {
    position: relative;
    bottom:30px;
}

.c-btn--underlined {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.c-btn--lg {
    width: 100%;

    @include breakpoint($md) {
        width: 460px;
    }
}