﻿/**
 * TYPOGRAPHY.HEADINGS
 *
 * @description : Main body level headings styles
 *
 */
.t-title_1 {
    font-family: $main-font;
    font-size: rem(46);
    color:$primary-blue;
    line-height: 1.2;

    @include breakpoint($md) {
        font-size:2.375em;
    }
}

.t-title_2 {
    font-family: $main-font;
    font-size: rem(46);
    color:$primary-blue;
    line-height: 1.2;

    @include breakpoint($md) {
        font-size:2em;
    }
}


.t-title_3 {
    font-family: $main-font;
    font-size: rem(43);
    color:$primary-blue;
}

.t-title_4 {
    font-size: rem(37);
}
.t-title_5 {
    font-family: $main-font;
    font-size: rem(28);
    color:$primary-blue;
    font-weight: 300;

    @include breakpoint($md) {
        font-size: rem(37);
    }
}
.t-title_6 {
    font-family: $main-font;
    font-size: rem(37);
    color:$primary-blue;
}
.t-title_7 {
    font-family: $main-font;
    font-size: rem(33);
    font-weight: 400;
    color:$primary-blue;
    line-height: 2;
}


.t-title--has_line {
    &::after {
        margin: 0.06em auto 0.2em 0;
        display: block;
        content: '';
        height: 2px;
        width: 1.8em;
        background-color: $environment-green;
    }
    
    .u-tc--mobile & {
        &:after {
            margin: 0.06em auto 0.2em auto;
        }
        @include breakpoint($md){
            &:after {
                margin: 0.06em auto 0.2em 0;
            }
        }
    }

    .u-tc &, &.u-tc  {
        &:after {
            margin: 0.06em auto 0.2em auto;
        }
    }
}

.t-bold {
    font-weight: bold !important;
}

.t-semi-bold {
    font-weight: 400 !important;
}