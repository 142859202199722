/**
 * COMPONENTS.GALLERY
 *
 * @description : Full-featured interactive photo gallery
 *
 */

$base: 16;

.c-gallery {
    display: block;
    overflow: hidden;
}

.c-gallery__title {
    margin-bottom: rem(32);
    text-align: center;

    @include breakpoint($sm) {
        text-align: left;
    }
}

.c-gallery__carousel {
    position: relative;
}

.c-gallery__nav-wrap {
    left: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    @include breakpoint(700px) {
        padding: 0 rem(80, $base);
    }
}

.c-gallery__nav {
    margin: 0 auto;
    max-width: rem(700, $base);
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: 83.14%;
    }
}

.c-gallery__prev,
.c-gallery__next {
    align-items: center;
    background: transparent;
    border-radius: 100vh;
    box-shadow: inset 0 0 0 rem(2, $base);
    color: #8CC63F;
    cursor: pointer;
    display: flex;
    height: rem(42, $base);
    justify-content: center;
    pointer-events: all;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s;
    width: rem(42, $base);

    @include breakpoint($md) {
        height: rem(52, $base);
        width: rem(52, $base);
    }

    &:hover {
        color: #50a712;
    }
}

.c-gallery__prev {
    margin-left: rem(16, $base);
    left: 0;

    @include breakpoint(700px) {
        left: auto;
        margin-left: auto;
        margin-right: rem(25, $base);
        right: 100%;
    }
}

.c-gallery__next {
    margin-right: rem(16, $base);
    right: 0;

    @include breakpoint(700px) {
        margin-left: rem(25, $base);
        margin-right: 0;
        left: 100%;
        right: auto;
    }
}

.c-gallery__nav-icon {
    display: block;
    fill: currentColor;
    height: rem(22, $base);
    left: rem(2, $base);
    position: relative;
    width: auto;

    .c-gallery__prev & {
        left: rem(-2);
        transform: rotate(180deg);
    }

    * {
        fill: inherit;
    }
}

.c-gallery__slide {
    margin: 0 auto;
    max-width: rem(700, $base);
    padding: 0;

    @include breakpoint(700px) {
        padding: 0 rem(80, $base);
    }
}

.c-gallery__figure {
    display: block;
}

.c-gallery__img-wrap {
    background: rgba(#000, 0.08);
    margin-bottom: rem(30, $base);
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: 75%;
    }
}

.c-gallery__img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.c-gallery__caption {

    @include breakpoint(0 ($md - 1px)) {
        p {
            font-size: rem(16, $base);
        }
    }
}

.c-gallery__count {
    color: #999;
    font-size: rem(16, $base);
    margin-top: rem(28, $base);

    @include breakpoint($md) {
        font-size: rem(18, $base);
    }
}

.c-gallery__thumbs {
    display: block;
}

.c-gallery__thumb-list {
    display: flex;
    flex-flow: row wrap;
    margin: rem(-6, $base);

    @include breakpoint($lg) {
        margin: rem(-10, $base);
    }

    &.is-limited {
        @include breakpoint(0 $sm) {
            &[data-gt6] {
                .c-gallery__thumb-item:nth-child(n+7) {
                    display: none;
                }

                & ~ .c-gallery__more-button {
                    display: block;
                }
            }
        }

        @include breakpoint($sm 700px) {
            &[data-gt8] {
                .c-gallery__thumb-item:nth-child(n+9) {
                    display: none;
                }

                & ~ .c-gallery__more-button {
                    display: block;
                }
            }
        }

        @include breakpoint(700px) {
            &[data-gt10] {
                .c-gallery__thumb-item:nth-child(n+11) {
                    display: none;
                }

                & ~ .c-gallery__more-button {
                    display: block;
                }
            }
        }
    }
}

@keyframes thumbItemFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.c-gallery__thumb-item {
    animation: 0.3s thumbItemFadeIn both;
    padding: rem(6, $base);
    position: relative;
    width: 33.333%;
    z-index: 1;

    @include breakpoint($sm) {
        width: 25%;
    }

    @include breakpoint(700px) {
        width: 20%;
    }

    @include breakpoint($lg) {
        padding: rem(10, $base);
    }

    &:hover {
        z-index: 2;
    }
}

.c-gallery__thumb-button {
    box-shadow: 0 0 rem(16, $base) rgba(#000, 0);
    cursor: pointer;
    display: block;
    position: relative;
    transform: scale(1);
    transition: 0.2s;
    width: 100%;

    &:hover {
        box-shadow: 0 0 rem(16, $base) rgba(#000, 0.2);
        transform: scale(1.22);
    }

    &::after {
        content: '';
        display: block;
        padding-top: 75%;
    }
}

.c-gallery__thumb-img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.c-gallery__more-button {
    @extend .c-btn;
    @extend .c-btn--pri;
    display: none;
    margin: rem(40) auto 0 auto;

    @include breakpoint($md) {
        margin-top: rem(60);
    }
}