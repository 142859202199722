/**
 * UTILITIES.LAYOUT
 *
 * @description : These classes trump other preceeding styles.
 *                Userful for quickly applying common adjustments.
 */


.u-fr {
    float: right;
}

.u-fl {
    float: left;
}

.u-m0 {
    margin:0;
}

.u-mb {
    margin-bottom: spacer();
}
.u-mb4 {
    margin-bottom: 32px;
}
.u-mb2 {
    margin-bottom: 16px;
}
.u-mb1 {
    margin-bottom: 8px;
}
.u-mt1 {
    margin-top: 8px;
}
.u-mt2 {
    margin-top: 16px;
}
.u-mt4 {
    margin-top: 32px;
}
.u-mr2 {
    margin-right: 16px;
}

@media (max-width: $md) {
    .u-mob-m0 {
        margin: 0;
    }
    .u-mob-mb {
        margin-bottom: spacer();
    }
    .u-mob-mb4 {
        margin-bottom: 32px;
    }
    .u-mob-mb2 {
        margin-bottom: 16px;
    }
    .u-mob-mb1 {
        margin-bottom: 8px;
    }
    .u-mob-mt1 {
        margin-top: 8px;
    }
    .u-mob-mt2 {
        margin-top: 16px;
    }
    .u-mob-mt4 {
        margin-top: 32px;
    }
    .u-mob-mr2 {
        margin-right: 16px;
    }
}

.u-inline {
    display: inline-block;
}

.u-full-width {
    width: 100%;
}

.u-desktop-only {
    @media all and (max-width:$md) {
        display: none;
    }
}
.u-lg-only {
    display: none;
    @media all and (min-width:$lg){
        display: block;
    }
}
.u-xl-only {
    @media all and (max-width:$xl){
        display: none;
    }
}
.u-mobile-only {
    @include breakpoint($md) {
        display: none;
    }
}
.u-visually-hidden {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

.u-border-bottom {
    border-bottom: 1px solid $border-blue;
}

.u-overflow-x-auto {
    overflow-x: auto;
}