.u-economic-purple-c {
    color:$economy-purple;
}
.u-default-blue-c {
    color:$primary-blue;
}
.u-regulatory-red-c {
    color: $regulatory-red;
}
.u-environment-green-c {
    color: $environment-green;
}
.u-local-democracy-cyan-c {
    color: $local-democracy-cyan;
}
.u-community-services-teal-c {
    color: $community-teal;
}
.u-financial-management-green-c {
    color: $financial-green;
}
.u-infrastructure-orange-c {
    color: $infrastructure-orange;
}
.u-regulatory-red-c {
    color: $regulatory-red;
}
.u-sport-and-recreation-blue-c {
    color: $sport-blue;
}
.u-library-blue-c {
    color: $library-blue;
}

.u-economic-purple-bg {
    background-color:$economy-purple;
}
.u-default-blue-bg {
    background-color:$primary-blue;
}
.u-regulatory-red-bg {
    background-color: $regulatory-red;
}
.u-environment-green-bg {
    background-color: $environment-green;
}
.u-local-democracy-cyan-bg {
    background-color: $local-democracy-cyan;
}
.u-community-services-teal-bg {
    background-color: $community-teal;
}
.u-financial-management-green-bg {
    background-color: $financial-green;
}
.u-infrastructure-orange-bg {
    background-color: $infrastructure-orange;
}
.u-regulatory-red-bg {
    background-color: $regulatory-red;
}
.u-sport-and-recreation-blue-bg {
    background-color: $sport-blue;
}
.u-library-blue-bg {
    background-color: $library-blue;
}

.u-economic-purple-border {
    &:hover {
        border-color:$economy-purple;
    }
}
.u-default-blue-border {
    &:hover {
        border-color:$primary-blue;
    }
}
.u-regulatory-red-border {
    &:hover {
        border-color: $regulatory-red;
    }
}
.u-environment-green-border {
    &:hover {
        border-color: $environment-green;
    }
}
.u-local-democracy-cyan-border {
    &:hover {
        border-color: $local-democracy-cyan;
    }
}
.u-community-services-teal-border {
    &:hover {
        border-color: $community-teal;
    }
}
.u-financial-management-green-border {
    &:hover {
        border-color: $financial-green;
    }
}
.u-infrastructure-orange-border {
    &:hover {
        border-color: $infrastructure-orange;
    }
}
.u-regulatory-warm-red-border {
    &:hover {
        border-color: $regulatory-warm-red;
    }
}
.u-sport-and-recreation-blue-border {
    &:hover {
        border-color: $sport-blue;
    }
}
.u-library-blue-border {
    &:hover {
        border-color: $library-blue;
    }
}

//Mapping to the actual Hex colors
.u-8c275b-c {
    color:$economy-purple;
}
.u-00395b-c {
    color:$primary-blue;
}
.u-e21f26-c {
    color: $regulatory-red;
}
.u-40860e-c {
    color: $environment-green;
}
.u-00adee-c {
    color: $local-democracy-cyan;
}
.u-009f90-c {
    color: $community-teal;
}
.u-006937-c {
    color: $financial-green;
}
.u-f6931d-c {
    color: $infrastructure-orange;
}
.u-e21f26-c {
    color: $regulatory-red;
}
.u-4485c6-c {
    color: $sport-blue;
}
.u-0170a4-c {
    color: $library-blue;
}

.u-8c275b-bg {
    background-color:$economy-purple;
}
.u-00395b-bg {
    background-color:$primary-blue;
}
.u-e21f26-bg {
    background-color: $regulatory-red;
}
.u-40860e-bg {
    background-color: $environment-green;
}
.u-00adee-bg {
    background-color: $local-democracy-cyan;
}
.u-009f90-bg {
    background-color: $community-teal;
}
.u-006937-bg {
    background-color: $financial-green;
}
.u-f6931d-bg {
    background-color: $infrastructure-orange;
}
.u-e21f26-bg {
    background-color: $regulatory-red;
}
.u-4485c6-bg {
    background-color: $sport-blue;
}
.u-0170a4-bg {
    background-color: $library-blue;
}

.u-8c275b-border {
    &:hover {
        border-color:$economy-purple;
    }
}
.u-00395b-border {
    &:hover {
        border-color:$primary-blue;
    }
}
.u-e21f26-border {
    &:hover {
        border-color: $regulatory-red;
    }
}
.u-40860e-border {
    &:hover {
        border-color: $environment-green;
    }
}
.u-00adee-border {
    &:hover {
        border-color: $local-democracy-cyan;
    }
}
.u-009f90-border {
    &:hover {
        border-color: $community-teal;
    }
}
.u-006937-border {
    &:hover {
        border-color: $financial-green;
    }
}
.u-f6931d-border {
    &:hover {
        border-color: $infrastructure-orange;
    }
}
.u-ef533f-border {
    &:hover {
        border-color: $regulatory-warm-red;
    }
}
.u-4485c6-border {
    &:hover {
        border-color: $sport-blue;
    }
}
.u-0170a4-border {
    &:hover {
        border-color: $library-blue;
    }
}