/**
 * TYPOGRAPHY.DISPLAY
 *
 * @description : Display Text
 *
 */

/* e.g.
    .t-display_1 {
        font-size: rem(120);
    }
    .t-display_2 {
        font-size: rem(90);
    }
    .t-display_3 {
        font-size: rem(60);
    }
*/