.c-link-list {
    color:$primary-blue;

    .c-section-sport & {
        margin-bottom: 32px;
        
        @include breakpoint($md) {
            margin-bottom: 72px;
        }
    }
}
.c-link-list--vertical {
    border-top: 1px solid rgba($gamma, 0.2);
    padding-top: 36px;

    .c-accordion & {
        border-top: 0;
    }
}
.c-link-list__title {
    color:$white;
    text-align: center;
    font-size:1.19em;
}
.c-link-list__list {
    text-align: center;
    width:calc(100% - 24px);
    margin:30px 12px 15px 12px;
    @include breakpoint($sm) {
        margin:30px 0 15px;
    }
}
.c-link-list__list-item {
    display: inline-block;
}
.c-link-list__link {
    background:$off-white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
    text-transform: uppercase;
    border: none;
    color:$marine;
    font-family: $component-label-font;
    border-bottom: 2px solid $environment-green;
    display: inline-block;
    padding: 0.61em 2em;
    font-size: .575em;
    font-weight: 500;
    margin:0 4px 16px 4px;
    @include breakpoint($md) {
        padding: 0.61em 2.8em;
        font-size: .715em;
        margin:0 15px 30px 15px;
    }
}

.c-link-list__column-vertical-flexed {
    display: flex;
    justify-content: space-around;

    .c-wrapper {
        display: flex;
        flex-direction: column
    }
}

.c-link-list__column-horizontal-flexed {
    .c-wrapper {
        display: flex;
        justify-content: center;
    }
}