/**
 * TYPOGRAPHY.RICHTEXT
 *
 * @description : Rich text for long-form ediorial
 *
 */

.t-rich_text {
    em {
        font-style: italic;
    }

    a[href^="mailto:"] {
        display: block;
        text-decoration: none;
        margin-top: 16px;

        &:before {
            content: "";
            display: inline-block;
            background-image: url("/Image/icons/email-green.svg");
            background-size: contain;
            width: 50px;
            height: 50px;
            vertical-align: middle;
            margin-right: 12px;
        }
    }

    ul {
        margin-bottom: 1.5em;

        li {
            padding-left: 1.5em;
            position: relative;
            color: $primary-blue;

            &::before {
                content: '';
                display: block;
                position: absolute;
                background-image: url('/Image/icons/list-chevron-right.svg');
                background-repeat: no-repeat;
                background-size: contain;
                top: 7px;
                left: 3px;
                width: 20px;
                height: 20px;
            }
        }
    }

    ol {
        ul {
            counter-increment: none;

            li {
                counter-increment: none;

                &::before {
                    content: "";
                    top: 7px;
                    left: 3px;
                }
            }
        }
    }

    ol {
        li {
            counter-increment: step-counter;
            position: relative;
            padding-left: 1.5em;

            &::before {
                content: counter(step-counter);
                position: absolute;
                left: 0;
                top: 0;
                color: $mid-grey;
            }
        }
    }

    table {
        width: 100%;
        color: $primary-blue;

        small {
            display: block;
            color: $mid-grey;
        }

        strong {
            font-weight: 600;
        }
    }

    tr {
        background-color: $offwhite-translucent;
        border-bottom: 1px solid $border-grey;

        &:first-child {
            background-color: $offwhite;

            th, td {
                border-left: 1px solid #CBD7D6;
                border-right: 1px solid #CBD7D6;
                border-top: 1px solid $offwhite;
            }
            th:first-of-type,
            td:first-of-type {
                border-left: 1px solid $offwhite;
            }
            th:last-of-type,
            td:last-of-type {
                border-right: 1px solid $offwhite;
            }
        }
    }

    th, td {
        &:first-child {
            text-align: left;
        }

        text-align: left;
    }

    th {
        padding-bottom: 12px;
    }

    td {
        padding: 1rem;
        border: 1px solid rgba(0, 57, 91, 0.2);
        font-weight: 300;

        @media (max-width: $md) {
            width: fit-content !important;
        }
    }

    strong, h1, h2, h3, h4, h5, p, span, div, small {
        color: $primary-blue;
    }

    .t-rich_text--remove-chevrons {
        margin-left: 1.5em;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }

    .t-rich_text--remove-numbers {
        margin-left: 1.5em;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }

    iframe {
        @media (max-width: $md) {
            width: 100%;
        }
    }
}


.t-rich_text--editorial {
    line-height: 1.7;


    @include breakpoint($md) {
        font-size: rem(18);
    }

}
