﻿.c-quick-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;

    @media (max-width: $lg) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 18px;
        row-gap: 0.5rem;
    }
}

.c-quick-links__heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 2rem;
    text-align: left;
    color: $primary-blue;

    @media (max-width: $lg) {
        text-align: center;
        padding: 0 18px;
    }

    &.c-standard-content__quick-links__heading {
        margin-bottom: 1rem;
    }
}

.c-quick-links__wrap {
    border-bottom: 1px solid $light-grey;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    column-gap: 1.5rem;
    padding-bottom: 1rem;
    text-decoration: none;
    transition: border-bottom-color .2s;

    &:hover {
        border-bottom-color: $lime-scooter;

        .c-quick-links__button-icon {
            right: 10px;
        }
    }

    @media (max-width: $lg) {
        padding-bottom: 0.5rem;
    }
}

.c-quick-links__icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
}

.c-quick-links__label-wrap {
    display: flex;
    align-items: center;
    flex: 1;
    text-align: left;
}

.c-quick-links__label {
    margin-bottom: 0 !important;
    font-size: 1rem;
    font-weight: bold;
}

.c-quick-links__button-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
}

.c-quick-links__button-icon {
    display: block;
    position: absolute;
    right: 0;

    transition: right .2s;
}