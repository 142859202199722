.c-pin {
}
.c-pin__svg {
    display: inline-block;
    vertical-align: top;
    margin-top:6px;
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top:-57px;
    }
}
.c-pin__content {
    margin-left:12px;
    display: inline-block;
}
.c-pin__label {
    color:$pin-text-color;
    margin-bottom: 6px;
    font-size: 0.8em;
    @include breakpoint($lg){
        font-size: 1em;
    }
}
.c-pin__caption {
    color:$pin-caption-color;
    font-size:.7em;
    @include breakpoint($lg){
        font-size:.8em;
    }
}