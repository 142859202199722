/**
 * MASTER.SCSS
 *
 * @Project     : Project Name
 * @Author      : AuthorName [author.name@nvinteractive.com]
 *
 */

// Set the viewport to resize responsively (similar to viewport meta tag)
@viewport {
    width: device-width;
    zoom: 1;
}

//  0. PLUGINS
@import '../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss';
@import '../../node_modules/swiper/swiper';

/** 
 * 1. SETTINGS
 */
@import '1.settings/settings.animations';
@import '1.settings/settings.base';
@import '1.settings/settings.breakpoints';
@import '1.settings/settings.colors';
@import '1.settings/settings.grid';
@import '1.settings/settings.zindex';
@import '1.settings/settings.font.helvetica';
@import '1.settings/settings.font.aller';

/**
 *  2. TOOLS
 */
@import '2.tools/tools.functions.scss';
@import '2.tools/tools.mixin.heading-scale.scss';
@import '2.tools/tools.mixin.position.scss';
@import '2.tools/tools.mixin.clearfix.scss';
@import '2.tools/tools.mixin.colors.scss';
@import '2.tools/tools.mixin.baseline_grid.scss'; // Adds a handy baseline grid for setting type.

/**
 * 3. GENERIC
 */
@import '3.generic/generic.reset';
@import '3.generic/generic.boxsizing';

/**
 *  4. ELEMENTS
 *  Basic styles for raw HTML elements
 *  No classes here
 */
@import '4.elements/elements.type';
@import '4.elements/elements.base';
@import '4.elements/elements.disabled';
@import '4.elements/elements.images';
@import '4.elements/elements.forms';

/**
 *  5. OBJECTS
 *  These are repeating visual/structural patterns with no 'design' added
 *  Must be built with classes only
 */
@import '5.objects/objects.grid';
@import '5.objects/objects.layout';
@import '5.objects/objects.map';
@import '5.objects/objects.module';
@import '5.objects/objects.filter-list';
@import '5.objects/objects.iframe';
@import '5.objects/objects.error';

/**
 *  6. TYPOGRAPHY
 *  All styles on top of resets that affect typography, including titles, paragraphs, lists, links etc.
 *  Prefer classes where possible
 */
@import '6.typography/typography.copy.scss';
@import '6.typography/typography.display.scss';
@import '6.typography/typography.richtext.scss';
@import '6.typography/typography.title.scss';
@import '6.typography/typography.lists.scss';

/**
 *  7. COMPONENTS
 *  A component is a reusable chunk of UI that is clearly designed, as opposed to an object.
 *  Clearly refers to a block of UI: pagination, buttons, modals, icons... etc
 *  You should be able to take a component and paste it anywhere in the app
 *  Must be built with classes only
 */
@import '7.components/components.accordion';
@import '7.components/components.alert';
@import '7.components/components.anchor-link';
@import '7.components/components.attribute-list';
@import '7.components/components.banner';
@import '7.components/components.breadcrumbs';
@import '7.components/components.buttons';
@import '7.components/components.card';
@import '7.components/components.checkbox';
@import '7.components/components.contact';
@import '7.components/components.cost-table';
@import '7.components/components.cta-slide-carousel';
@import '7.components/components.dropdown';
@import '7.components/components.detail-list';
@import '7.components/components.fees-table';
@import '7.components/components.field';
@import '7.components/components.filter-header';
@import '7.components/components.footer';
@import '7.components/components.gallery';
@import '7.components/components.header';
@import '7.components/components.hero-withcards';
@import '7.components/components.keyword-search';
@import '7.components/components.meeting-item';
@import '7.components/components.menu';
@import '7.components/components.news';
@import '7.components/components.modal';
@import '7.components/components.offwhite-panel';
@import '7.components/components.pagination';
@import '7.components/components.pin';
@import '7.components/components.radio';
@import '7.components/components.rubbish-recycling';
@import '7.components/components.related-content';
@import '7.components/components.related-news';
@import '7.components/components.large-input';
@import '7.components/components.listing';
@import '7.components/components.quick-links-with-icon';
@import '7.components/components.section-sport';
@import '7.components/components.sharing';
@import '7.components/components.sidenav';
@import '7.components/components.signup-tag';
@import '7.components/components.search';
@import '7.components/components.slider';
@import '7.components/components.spinner';
@import '7.components/components.toolbar';
@import '7.components/components.timetable';
@import '7.components/components.link-list';
@import '7.components/components.upload';
@import '7.components/components.table';
@import '7.components/components.video-modal';
@import '7.components/components.video-thumb';

/**
 *  8. UTILITIES
 */
@import '8.utilities/utilities.color';
@import '8.utilities/utilities.flex';
@import '8.utilities/utilities.theme';
@import '8.utilities/utilities.layout';
@import '8.utilities/utilities.spacing';
@import '8.utilities/utilities.type';
@import '8.utilities/utilities.validation';
