﻿/**
 * TYPOGRAPHY.COPY
 *
 * @description : Main body copy styles
 *
 */

/* e.g.
    .t-body {
        font-size: rem(18);
    }
*/

.t-body-copy-sm {
    font-size: rem(14);
}