.o-module {
    margin-bottom: rem(90);
    
    @include breakpoint($md) {
        margin-bottom: rem(110);
    }
    @include breakpoint($lg) {
        margin-bottom: rem(120);
    }
}

.o-module--newsletter {
    padding-top: rem(90);
    border-top:1px solid $border-grey;
    
    @include breakpoint($md) {
        padding-top: rem(110);
    }
    @include breakpoint($lg) {
        padding-top: rem(120);
    }

    .home & {
        border-top:none;
        padding-top:0;
    }

    &--no-border {
        border-top: none !important;
    }
}

.o-module--anchor { 
    margin-top: -70px;
    padding-top: 70px;

    @include breakpoint($menu) {
        margin-top: -140px;
        padding-top: 140px;
    }
}

.o-module--border-top {
    border-top: rem(1px) solid $border-grey;
}

.o-module--quarter {
    margin-bottom: rem(22.5);
    
    @include breakpoint($md) {
        margin-bottom: rem(27.5);
    }
    @include breakpoint($lg) {
        margin-bottom: rem(30);
    }
}
.o-module--half {
    margin-bottom: rem(45);
    
    @include breakpoint($md) {
        margin-bottom: rem(55);
    }
    @include breakpoint($lg) {
        margin-bottom: rem(60);
    }
}

.o-module--side-m {
    margin-left:15px;
    margin-right:15px;

    @include breakpoint(1350px) {
        margin-left:auto;
        margin-right:auto;
    }
}

.o-module-hr {
    margin:0 0 2em;
    @include breakpoint($md){
        margin:0 0 3em;
    }
}
.o-module--escape-grid {
    position: static;
    width:100vw;
    margin-left:-29px;

    @include breakpoint($lg){
        margin-left:-36%;
    }
    //(bodymax - width) / 2 - width of side panel
    @include breakpoint($bodyMax){
        margin-left: calc(((83.5rem - 100vw) / 2) - 36.9%);
        margin-right: calc((83.5rem - 100vw) / 2);
    }
}
