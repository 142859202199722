.c-field {
    margin-bottom: 32px;

    @include breakpoint($md) {
        margin-bottom: 53px;
    }

    &.recaptchav3withscore,
    &.hidden {
        margin-bottom: 0;
    }
}
.c-field__input {
    height: 55px;
}
.c-field__label {
    font-family: $main-font;
    font-weight: 100;
    color:$field-label-color;
    line-height: 1.2em;
    
    @include breakpoint($md) {
        padding-top:19px;
    }

    .gi-md-4 & {
        padding-right:30px;
    }
}

.c-field__tooltip {
    font-size:.625em;
    color:$field-label-color;
    letter-spacing: normal;
    display: block;
    margin-bottom: 6px;
    
    @include breakpoint($md) {
        margin-bottom: 0px;
    }


    .gi-md-4 & {
        padding-right:30px;
    }
}
.c-field--select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 55px;
    background: linear-gradient(45deg, transparent 50%, $field-select-arrow 50%), linear-gradient(135deg, $field-select-arrow 50%, transparent 50%);
    background-position: calc(100% - 31px) calc(1em + 4px), calc(100% - 26px) calc(1em + 4px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    margin-bottom: 0;
    cursor: pointer;
    margin: 0.5em 0;

    option {
        font-family: $main-font;
        font-weight: 100;
    }
}

.umbraco-forms-page .c-textarea {
    border: 1px solid #D5E0E4 !important;
    max-width: none !important;
    padding: 0.6em 0.9em !important;
    width: 100% !important;

    &:focus {
        border: 1px solid #6e6e6e !important;
    } 
}

.umbraco-forms-hidden {
    display: none !important;
}