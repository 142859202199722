/**
 * COMPONENTS.TOOLBAR
 *
 * @description : Houses breadcrubms and sharing links
 *
 */

.c-toolbar {
    border-bottom: 1px solid $border-grey;
    display: flex;
    padding: rem(20) 0;
    margin: 0 12px;
    
    @include breakpoint($md) {
        padding: rem(44) 0;
    }
}

.c-toolbar__minor {
    margin-left: auto;
    display: flex;
}

.c-toolbar__button {
    margin-left: 1em;
    display: flex;
    align-items: center;
    
    @include breakpoint(0, $md) {
        padding: 0.5em 0.75em;
    }
    @include breakpoint($md) {
        margin-left: 2em;
    }
}