.c-anchor-link {
    text-decoration: none;
    font-weight: 300;
    font-size:rem(33);
    color: $anchor-link-color;
    transition: color 0.4s ease;
    padding: 12px 0;
    display: block;
    text-align: left;

    &:before {
        content:"";
        transition: background 0.4s ease;
        display: inline-block;
        background: url("/Image/icons/circle-arrow-down-grey.svg") no-repeat;    
        width:42px;
        height:42px;
        margin-right: 11px;
        vertical-align: middle;
    }
    
    &:hover {
        color: $anchor-link-hover-color;
        
        &:before {
            background: url("/Image/icons/circle-arrow-down.svg") no-repeat;
        }
    }

    @include breakpoint($lg) {
        display: inline-block;
        margin-right:40px;
        padding: 32px 0 12px;
    }
}