﻿/**
 * UTILITIES.FLEX
 *
 * @description : 
 *
 */


.u-flex {
    display: flex;
}

.u-flex-- {
    display: grid;
}

.u-flex-row {
    flex-direction: row;
}

.u-flex-column {
    flex-direction: column;
}

.u-justify-between {
    justify-content: space-between;
}

.u-justify-around {
    justify-content: space-around;
}

.u-justify-center {
    justify-content: center;
}

.u-justify-end {
    justify-content: flex-end;
}

.u-justify-start {
    justify-content: flex-start;
}

.u-align-center {
    align-items: center;
}

.u-align-start {
    align-items: flex-start;
}

.u-align-end {
    align-items: flex-end;
}

.u-align-baseline {
    align-items: baseline;
}

@media (max-width: $md) {
    .u-mob-flex {
        display: flex;
    }

    .u-mob-flex-row {
        flex-direction: row;
    }

    .u-mob-flex-column {
        flex-direction: column;
    }

    .u-mob-justify-between {
        justify-content: space-between;
    }

    .u-mob-justify-around {
        justify-content: space-around;
    }

    .u-mob-justify-center {
        justify-content: center;
    }

    .u-mob-justify-end {
        justify-content: flex-end;
    }

    .u-mob-justify-start {
        justify-content: flex-start;
    }

    .u-mob-align-center {
        align-items: center;
    }

    .u-mob-align-start {
        align-items: flex-start;
    }

    .u-mob-align-end {
        align-items: flex-end;
    }

    .u-mob-align-baseline {
        align-items: baseline;
    }
}

.u-gap-0 {
    gap: 0;
}

.u-gap-1 {
    gap: 4px;
}

.u-gap-2 {
    gap: 8px;
}

.u-gap-3 {
    gap: 16px;
}

.u-gap-4 {
    gap: 24px;
}

.u-gap-5 {
    gap: 32px;
}

.u-gap-6 {
    gap: 48px;
}

.u-gap-7 {
    gap: 56px;
}

.u-gap-8 {
    gap: 64px;
}

.u-gap-9 {
    gap: 80px;
}

.u-gap-10 {
    gap: 96px;
}

.u-gap-11 {
    gap: 128px;
}

.u-gap-12 {
    gap: 160px;
}

.u-grid {
    display: grid;
}

.u-grid-cols-1 {
    grid-template-columns: 1fr;
}

.u-grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.u-grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.u-grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

.u-grid-cols-5 {
    grid-template-columns: repeat(5, 1fr);
}

.u-grid-cols-6 {
    grid-template-columns: repeat(6, 1fr);
}

.u-grid-cols-7 {
    grid-template-columns: repeat(7, 1fr);
}

.u-grid-cols-8 {
    grid-template-columns: repeat(8, 1fr);
}

.u-grid-cols-9 {
    grid-template-columns: repeat(9, 1fr);
}

.u-grid-cols-10 {
    grid-template-columns: repeat(10, 1fr);
}

.u-grid-cols-11 {
    grid-template-columns: repeat(11, 1fr);
}

.u-grid-cols-12 {
    grid-template-columns: repeat(12, 1fr);
}
/* ~~~ Mobile Styles ~~~ */

.u-mob-flex {
    @media (max-width: $md) {
        display: flex;
    }
}

.u-mob-flex-row {
    @media (max-width: $md) {
        flex-direction: row;
    }
}

.u-mob-flex-column {
    @media (max-width: $md) {
        flex-direction: column;
    }
}

.u-mob-justify-between {
    @media (max-width: $md) {
        justify-content: space-between;
    }
}

.u-mob-justify-around {
    @media (max-width: $md) {
        justify-content: space-around;
    }
}

.u-mob-justify-end {
    @media (max-width: $md) {
        justify-content: flex-end;
    }
}

.u-mob-justify-start {
    @media (max-width: $md) {
        justify-content: flex-start;
    }
}

.u-mob-align-center {
    @media (max-width: $md) {
        align-items: center;
    }
}

.u-mob-align-start {
    @media (max-width: $md) {
        align-items: flex-start;
    }
}

.u-mob-align-end {
    @media (max-width: $md) {
        align-items: flex-end;
    }
}

.u-mob-gap-0 {
    @media (max-width: $md) {
        gap: 0;
    }
}

.u-mob-gap-1 {
    @media (max-width: $md) {
        gap: 4px;
    }
}

.u-mob-gap-2 {
    @media (max-width: $md) {
        gap: 8px;
    }
}

.u-mob-gap-3 {
    @media (max-width: $md) {
        gap: 16px;
    }
}

.u-mob-gap-3 {
    @media (max-width: $md) {
        gap: 24px;
    }
}

.u-mob-gap-5 {
    @media (max-width: $md) {
        gap: 32px;
    }
}

.u-mob-gap-6 {
    @media (max-width: $md) {
        gap: 48px;
    }
}

.u-mob-gap-7 {
    @media (max-width: $md) {
        gap: 56px;
    }
}

.u-mob-gap-8 {
    @media (max-width: $md) {
        gap: 64px;
    }
}

.u-mob-gap-9 {
    @media (max-width: $md) {
        gap: 80px;
    }
}

.u-mob-gap-10 {
    @media (max-width: $md) {
        gap: 96px;
    }
}

.u-mob-gap-11 {
    @media (max-width: $md) {
        gap: 128px;
    }
}

.u-mob-gap-12 {
    @media (max-width: $md) {
        gap: 160px;
    }
}

.u-mob-grid {
    @media (max-width: $md) {
        display: grid;
    }
}

.u-mob-grid-cols-1 {
    @media (max-width: $md) {
        grid-template-columns: 1fr;
    }
}

.u-mob-grid-cols-2 {
    @media (max-width: $md) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.u-mob-grid-cols-3 {
    @media (max-width: $md) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.u-mob-grid-cols-4 {
    @media (max-width: $md) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.u-mob-grid-cols-5 {
    @media (max-width: $md) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.u-mob-grid-cols-6 {
    @media (max-width: $md) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.u-mob-grid-cols-7 {
    @media (max-width: $md) {
        grid-template-columns: repeat(7, 1fr);
    }
}

.u-mob-grid-cols-8 {
    @media (max-width: $md) {
        grid-template-columns: repeat(8, 1fr);
    }
}

.u-mob-grid-cols-9 {
    @media (max-width: $md) {
        grid-template-columns: repeat(9, 1fr);
    }
}

.u-mob-grid-cols-10 {
    @media (max-width: $md) {
        grid-template-columns: repeat(10, 1fr);
    }
}

.u-mob-grid-cols-11 {
    @media (max-width: $md) {
        grid-template-columns: repeat(11, 1fr);
    }
}

.u-mob-grid-cols-12 {
    @media (max-width: $md) {
        grid-template-columns: repeat(12, 1fr);
    }
}