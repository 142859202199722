.c-listing {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border-bottom: 4px solid #D8D8D8;
    text-decoration: none;
    transition: border-color 0.25s ease-out height 0.75s ease-out;
    position: relative;
    margin-bottom: 51px;
    background:$listing-bg;
    color:$listing-text-colour;
    height: auto;
    
    &:hover {
        border-color:$listing-border;
        cursor: pointer;
    }
}
.c-listing__close-btn {
    display: inline-block;
    padding:20px;
    background:none;
    font-size:1.2rem;
    color:$listing-link-colour;
    font-family: $component-label-font;
    margin-left: 6px;

    &:hover {
        cursor: pointer;
    }

    svg {
        vertical-align: -5px;
        display: inline-block;
        width:24px;
        height:24px;
    }
}
.c-listing__button {
    display: flex;
    transition: opacity 0.25s ease-out;

    .c-listing--open & {
        opacity: 0;
        pointer-events: none;
    }
}
.c-listing__copy {
    font-size:1rem;
    color: $black;
    height: 50px;
    display: block;
    overflow: hidden;

    p {
        font-size:1rem;
        margin-bottom: 0;
    }
}
.c-listing__description {
    margin:0 10px;
    color:$listing-text-colour;
    
    @include breakpoint($md){
        margin:0;
    }
}
.c-listing__title {
    margin-top: 4px;
    margin-bottom: 16px;

    .c-listing__full & {
        margin-left: 20px;
    }

}
.c-listing__full {
    margin-top:-200px;
    padding-bottom: 32px;
    background:$listing-bg;
}
.c-listing__content {
    display: inline-block;
    padding:16px 21px 11px 21px;
    vertical-align: top;
    overflow: hidden;
}
.c-listing__image {
    min-width:200px;
    height:200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
}
.c-listing__detail {
    display: inline-block;
    margin-top:16px;
    margin-right:16px;
}
.c-listing__detail-link {
    font-size: 19px;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
}
.c-listing__carousel-slide { 
    display: block;
    width:100%;
    height:100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.c-listing__carousel { 
    width:100%;
    height:280px;
    margin-bottom: 30px;
    background:$listing-bg;
}
.c-listing__carousel-btn {
    height:40px;
    width:40px;
    position: absolute;
    top:50%;
    transform:translateY(-50%) rotate(90deg);
    left:30px;
    background:url("/Image/icons/circle-arrow-down.svg");
    z-index: $carousel-pagination;
}
.c-listing__carousel-btn--next {
    transform:translateY(-50%) rotate(-90deg);
    right:30px;
    left: auto;
}
.c-listing-map {
    height: 320px;
    margin-top:32px;
    margin-bottom:-32px;
    width:100%;
    
    .c-listing__full--page & {
        height: 520px;
    }
}
.c-listing__details {
    display: none;

    @include breakpoint($md){
        display: block;
    }
}