.c-timetable {
    font-family: $component-label-font;
    text-align: left;
    display: none;
    table {
        border-spacing: 3px;
        border-collapse: separate;
        width:100%;
    }
    
    th, td {
        box-shadow: 0 0 4px 0 $timetable-border-color;
        background: $timetable-bg;
        padding:7px 7px 0px 7px;
        border: 1px solid $timetable-bg;
        transition: background-color 0.35s ease, border 0.35s ease;
        text-align: left;
        
        @include breakpoint($xl) {
            padding:15px 15px 0px 15px;
        }

        &.c-timetable--class-active {
            border-color: $timetable-active-color;
            background: $timetable-active-bg;
        }
    }
    thead tr {
        height: 34px;
        @include breakpoint($xl){
            height: 50px;
        }
    }
    thead th, .c-timetable__cell-inverse {
        background:$timetable-header-bg;
        color:$white;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
        padding-bottom: 7px;
        border:none;
    }
    @include breakpoint($md){
        display: block;
    }
}
.c-timetable--rte {
    table {
        width: 100% !important;
    }
    td, th, tr {
        height: auto !important;
        vertical-align: middle;
        padding:12px 0px 0px 15px;
    }
}
.c-timetable__detail{
    font-size: 9px;
    color:$timetable-time-color; 
    font-weight: bold;
    margin:-5px 0 0;
}
.c-timetable__time{
    color:$timetable-time-color; 
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 7px;

    .c-timetable__cell-inverse & {
        font-size: 12px;
        color:$white;
    }
}
.c-timetable__class {
    color:$timetable-color; 
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 7px;
}
.c-timetable__filters {
    margin-bottom: 28px;
}
.c-timetable__title {
    font-size:1.185em;
}
.c-timetable__class-item {
    box-shadow: 0 0 4px 0 $accordion-box-shadow;
    padding:12px 23px 23px 23px;
    margin-bottom: 3px;
}