/**
 * MIXIN.COLORS
 *
 * @description : Foreground and background color mixins.
 * In addition to setting the color/background-color properties, 
 * these mixins also configure CSS custom properties so that:
 *
 * color: var(--bg-color);
 *
 * is possible, as well as custom properties relating to accent colors.
 *
 */

@mixin fg-color($color) {
    --fg-color: #{$color};
    // --fg-accent-color: TODO;
    color: $color;
}

@mixin bg-color($color) {
    --bg-color: #{$color};
    // --bg-accent-color: TODO;
    background-color: $color;
}