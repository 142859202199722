﻿.c-news {
    padding: 2rem 0;
}

.c-news__grid {
    display: grid;
    grid-template-columns: 1.8fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;

    @media (max-width: $lg) {
        padding: 0 18px;
        grid-template-columns: 1fr;
        grid-template-rows: unset;
    }
}

.c-news__main {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    min-height: 100%;

    .c-news__card-separator {
        height: 3px;
        width: 90px;
    }

    @media (max-width: $lg) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
}

.c-news__second {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    @media (max-width: $lg) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

.c-news__third {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    @media (max-width: $lg) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
}

.c-news__feed {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;

    @media (max-width: $lg) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }
}

.c-news__card {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
    text-decoration: none;
    transition: box-shadow .2s;

    &:not(:hover) {
        .c-news__card-trim {
            background-color: $light-grey !important;
        }
    }

    &:hover {
        box-shadow: 0 2px 30px 10px rgba(0,0,0,0.2);
    }
}

.c-news__card-image {
    padding-top: 60%;
    background-position: center center;
    background-size: cover;
    position: relative;

    .c-news__second &,
    .c-news__third & {
        padding-top: 50%;
    }

    @media (max-width: $lg) {
        padding-top: 50%;
    }
}

.c-news__card-tag {
    padding: 5px 10px;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    font-family: $secondary-font;
    font-weight: bold;
}

.c-news__card-content {
    background-color: $white;
    padding: 40px;
    /*border-bottom: 4px solid $light-grey;*/
    transition: border-bottom-color .2s;
    flex-grow: 1;

    .c-news__second &,
    .c-news__third & {
        padding: 30px;
    }

    @media (max-width: $lg) {
        padding: 30px;
    }
}

.c-news__card-header {
    width: 100%;
}

.c-news__card-date {
    font-size: 15px;
    line-height: 21px;
    color: $concrete;
    text-transform: uppercase;
    font-family: $secondary-font;
    font-weight: 500;
}

.c-news__card-separator {
    height: 3px;
    width: 50px;
    margin: 0.5rem 0 1rem 0 !important;
}

.c-news__card-heading {
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: $tasman-mako;
    margin-bottom: 20px;
    font-family: $secondary-font;
    font-weight: bold;

    .c-news__second &,
    .c-news__third & {
        font-size: 19px;
        line-height: 23px;
    }

    @media (max-width: $lg) {
        font-size: 18px;
        line-height: 23px;
    }
}

.c-news__card-desc {
    font-size: 18px;
    line-height: 27px;
    color: $black;
    font-weight: 100;
}

.c-news__card-trim {
    height: 4px;
    transition: background-color 0.2s ease;
}

.c-news__feed {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: $lg) {
        text-align: center;
    }
}

.c-news__feed-heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    font-family: $secondary-font;
    font-weight: bold;
}

.c-news__feed-item {
    display: block;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: $tasman-mako;
    transition: color .2s;
    font-family: $secondary-font;
    font-weight: bold;

    &:hover {
        color: $lime-scooter;
    }
}

.c-news__feed-item__separator {
    height:3px;
    width: 50px;
    background-color: $lime-scooter;
    margin: 2rem 0 1rem 0 !important;

    @media (max-width: $lg) {
        display: block;
        margin-top: 1rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.c-news__cta {
    min-width: 100%;
    margin-top: auto;
    background-color: $old-green;

    @media (max-width: $lg) {
        margin-top: 2rem;
    }
}
