/**
 * UTILITIES.TYPE
 *
 * @description : These classes trump other preceeding styles.
 *                Userful for quickly applying common adjustments.
 */


.u-tc {
    text-align: center;
}

.u-tr {
    text-align: right;
}

.u-tc--mobile {
    text-align: center;

    @include breakpoint($md) {
        text-align: left;
    }

    iframe {
        @media (max-width: $md) {
            width: 100%;
        }
    }
}

.u-tl {
    text-align: left;
}

.u-t--blue {
    color:$primary-blue;
}

.u-t--concrete {
    color: $concrete;
}