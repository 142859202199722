.c-pagination__list {
    margin-bottom: 60px;
}
.c-pagination__item {
    display: inline-block;
    margin-right:16px;
}
.c-pagination__link--current {
    color:$primary-blue;
    text-decoration: none;
}