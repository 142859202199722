.c-table {
    //display: none;
    font-family: $main-font;
    text-align: left;
    position: relative;

    table {
        border-spacing: 0px;
        border-collapse: collapse;
        width: 100%;
        //border: 3px solid rgb(232,232,232);

        p {
            font: inherit;
            margin-bottom: .5em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    th, td {
        background: $timetable-bg;
        //box-shadow: 0 0 4px 0 $timetable-border-color;
        //border: 3px solid rgb(232,232,232);
        font-size: 13px;
        padding: 5px 7px;
        text-align: left;

        @include breakpoint($xl) {
            padding: 10px 12px;
        }
    }

    td {
        border: 1px solid rgb(232,232,232);
    }

    th {
        background: $timetable-header-bg;
        //border: none;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        border-right: 1px solid rgb(232,232,232);

        &:last-of-type {
            border-right: none;
        }
    }

    thead tr {
        height: auto;

        @include breakpoint($xl) {
            height: 50px;
        }
    }

    @media (max-width: $md) {
        thead {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }

        tr td:first-of-type {
            font-size: 10px;
        }

        & {
            max-height: 80vh;
            overflow-y: scroll;
            border-bottom: 1px solid rgb(232,232,232);
        }
    }
}
