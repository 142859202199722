
@font-face {
    font-family: "Helvetica Neue";
    src: url("../fonts/helvetica/HelveticaNeueBold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("../fonts/helvetica/HelveticaNeueMedium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("../fonts/helvetica/HelveticaNeueRegular.ttf");
    font-weight: 400;
}