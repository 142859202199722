.c-sidenav {
    margin-top:42px;
    margin-bottom:82px;
}
.c-sidenav__link--second-level {
    .c-sidenav__item--active & {
        color: $sidenav-link-active-color;
    }
}
.c-sidenav__link {
    text-decoration: none;
    color:$sidenav-link-color;
    font-size:16px;
    line-height: 30px;
    //padding: 6px 0;
    display: block;
    font-weight: bold;

    &:hover {
        color:$sidenav-link-active-color;
    }

    .c-sidenav__sub-items & {
        font-weight: normal;
    }
}

.c-sidenav__link--active {
    color:$sidenav-link-active-color;
}

.c-sidenav__link--top-level {
    font-size:rem(42);
    font-weight: normal;
    margin-bottom: 1rem;
}
.c-sidenav__sub-items {
    margin:10px auto 20px 30px;
}

.c-standard-content__title {
    color: $primary-blue;
    font-size: 48px;
    line-height: 48px;
    font-weight: bold;

    &.t-title--has_line {
        &::after {
            margin-top: 3rem;
        }
    }
}

.c-standard-content__quick-links {
    .c-quick-links__label {
        font-size: 20px;
        font-weight: normal;
    }
}