/**
 * COMPONENTS.SEARCh
 *
 * @description :
 *
 */
.c-search__result {
    margin-bottom: 32px;
}
.c-search__toolbar-results {
    color:$primary-blue;
    margin-bottom: 0;
    margin-top: 12px;
}
.c-search__toolbar-helper {
    color:$primary-blue;
    font-weight: 400;
    font-size: 18px;
    font-weight: 100;
    background: rgba(80, 167, 18, 0.1);
    display: inline-block;
    padding:8px 12px;
    border-radius: 4px;
    margin-top:7px;
    opacity: 0;
    transform: translateX(-50px);
    transition: all 0.45s ease-out;
    transition-delay: 700ms;

    .search-loaded & {
        opacity: 1;
        transform: translateX(0);
    }
}
.c-search__toolbar-label {
    font-weight: 100;
    color:$primary-blue;
}
.c-search__result-link {
    font-size: 24px;
}
.c-search__result-createDate {
    font-weight: 100;
    font-size: 14px;
    color:$primary-blue;
}
.c-search__result-description {
    margin-bottom: 8px;
}
.c-search__result-subname {
    font-size: 18px;
}
.c-search__results-file-details {
    font-weight: 100;
    font-size: 19px;
    margin-left:7px;
    color:$primary-blue;
}