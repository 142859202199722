/**
 * COMPONENTS.VIDEO-MODAL
 *
 * @description : A modal to display the currently playing video.
 *
 */

.c-video-modal {
    @include bg-color(rgba(#000, 0.9));
    @include fg-color(#fff);
    position: relative;
}

.c-video-modal__outer-wrap {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: auto;
    width: 100%;
}

@keyframes c-video-modal__video-wrap {
    0% {
        transform: scale(0.7);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(0.7);
    }
}

.c-video-modal__video-wrap {
    flex: 1 1 auto;
    padding: rem(90px) 0;
    position: relative;

    @media (min-width: $md) {
        padding: rem(40px) rem(120px);
    }

    @media (min-width: $md) and (orientation: portrait) {
        padding: rem(120px) rem(40px);
    }

    &::after {
        --loading-icon-size: #{rem(20px)};
        background: currentColor;
        border-radius: 100vw;
        content: '';
        display: block;
        height: var(--loading-icon-size);
        left: 50%;
        margin: calc(var(--loading-icon-size) * -0.5) 0 0 calc(var(--loading-icon-size) * -0.5);
        opacity: 1;
        position: absolute;
        top: 50%;
        transform-origin: 50% 50%;
        transition: 0.5s;
        width: var(--loading-icon-size);

        .c-video-modal:not([hidden]) & {
            animation: c-video-modal__video-wrap 1s both 10 ease-in-out;
        }
    }
}

.c-video-modal__iframe {
    background: transparent;
    display: block;
    height: 100%;
    object-fit: contain;
    position: relative;
    width: 100%;
    z-index: 2;
}

.c-video-modal__info-bar {
    @include bg-color(#000);
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: rem(10px);
    padding: rem(40px) 20px;

    @media (min-width: $md) {
        padding: rem(40px) rem(120px);
    }

    @media (min-width: $md) and (orientation: portrait) {
        padding: rem(120px) rem(40px);
    }
}

.c-video-modal__heading-bar {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    gap: rem(10px) rem(24px);
}

.c-video-modal__close-button {
    --icon-size: #{rem(40px)} !important;
    position: absolute;
    right: var(--icon-size);
    top: var(--icon-size);
    width: var(--icon-size);
    z-index: 10;
    cursor: pointer;

    @media (min-width: $md) {
        --size: #{rem(50px)};
    }
}

.c-video-modal__info-bar {
    //padding: rem(90px) 0;
}

.c-video-modal__heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
    margin-bottom: 8px;
}

.c-video-modal__copy {
    p {
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 8px;
    }

    &:last-child {
        margin-bottom: rem(0) !important;
    }
}
