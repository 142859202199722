.c-banner {
    position: relative;
    overflow: hidden;
    max-height: 380px;

    @include breakpoint($md) {
        min-height: 480px;
        max-height: 680px;
    }

    .o-module--inside-banner & {
        @include breakpoint($md) {
            min-height: 480px;
            max-height: 510px;
        }   
    }
}
.c-banner--graphic {
    max-height: unset;
    min-height: unset;
    
    @include breakpoint($md) {
        max-height: unset;
        min-height: unset;
    }
}
.c-banner__image {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width:100%;
    height:480px;

    .c-banner--graphic & {
        height: auto;
        max-height: 480px;
        @include breakpoint($md) {
            max-height: none;
        }
    }

    @include breakpoint($xl) {
        height:680px;
    }
}
.c-banner__inner {
    .c-banner--left & {
        @include breakpoint($md) {
            margin-left:60px;
        }
    }
    .c-banner--graphic & {
        @include breakpoint($md) {
            margin-left:0;
        }
    }
}
.c-banner__content {
    color: $white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    justify-content: flex-end;
    padding: 20px 20px 50px 20px;

    &:before {
        position: absolute;
        mix-blend-mode: hard-light;
        width:100%;
        height:100%;
        top: 0;
        left:0;
        content:"";
        display: block;
        background-color: $banner-blend-color;
        pointer-events: none;
    }

    .c-banner--no-text & {
        flex-direction: row;
        padding: 20px 20px 82px 20px;

        &::before {
            display:none;
        }

        @include breakpoint($xl) {
            padding: 0 0 82px;
        }
    }
    
    .c-banner--graphic & {
        padding: 20px 20px 30%;
        
        @include breakpoint($md) {
            padding: 30% 20px 20px;
        }
        @include breakpoint($lg) {
            padding: 30% 20px 40px 20px;
        }

        .o-module--inside-banner & {
            @include breakpoint($lg) {
                height:82%;
                padding:30% 20px 0px 20px;
            }   
        }

        &::before {
            display:none;
        }
    }

    //blend modes doesn't work on IE, EDGE
    /* IE10+ CSS */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:before {

            opacity: 0.5;
        }
    }
    /* Edge 12-15 CSS */ 
    @supports (-ms-accelerator:true) {
        &:before {
            opacity: 0.5;
        }
    }
     /* Edge 16+ CSS */ 
    @supports (-ms-ime-align:auto) {
        &:before {
            opacity: 0.5;
        }
    }
        
    @include breakpoint($lg) {
        padding: 20px 20px 70px 20px;
    }
    @include breakpoint($xl) {
        padding: 20px 20px 112px 20px;
    }

    @include breakpoint($xl) {
        padding: 0 0 112px;
    }
}
.c-banner__block {
    .c-banner--graphic & {
        width:100%;   
    }
    .c-banner--left & {
        width:100%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: none !important;
    }
}
.c-banner__copy {
    font-size: rem(21);

    @include breakpoint($md){
        font-size: rem(28);
    }
    .c-banner--left & {
        max-width: 80%;
        
        @include breakpoint($lg){
            max-width: none;
        }
    }
}
.c-banner__title {
    color: $white;
    line-height: 1.1;
    font-size: rem(56);
    margin-bottom: 10px;

    @include breakpoint($sm){
        margin-bottom: 20px;
    }
    @include breakpoint($md){
        margin-bottom: 30px;
        font-size: rem(67);
    }
    .c-banner--left & {
        max-width: 80%;

        @include breakpoint($lg){
            max-width: none;
        }
    }
}
.c-banner__btn {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    .c-banner--graphic & {
        position: absolute;
        top: 0;

        @include breakpoint($md) {
            top: auto;
            bottom: 0;
        }

        @include breakpoint($xl) {
            bottom: 40px;
        }
    }

    .c-banner--no-text & {
        margin: auto auto 0 0;
    }

    @include breakpoint($sm) {
        width: auto;
    }
}
.c-banner__text {
    margin:auto 0 10px;

    @include breakpoint($md) {
        margin:auto auto 10px auto;
        max-width: 85%;
    }
    @include breakpoint(794px){
        min-width: 764px;
    }
    @include breakpoint($lg) {
        max-width: 65%;
    }
    @include breakpoint($xl) {
        max-width: 50%;
    }

    .c-banner--left & {
        max-width: 100%;
    }
}