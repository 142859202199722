.c-dropdown {
    position: relative;
    min-width: 150px;
    //margin-bottom: 30px;
    margin-bottom: 8px;

    @include breakpoint($md) {
        margin-bottom: initial;
    }
}
.c-dropdown__title {
    color: $checkbox-title-color;
    font-size: 16px;
    line-height: 1;

    @include breakpoint($md) {
        margin-bottom: 8px;
    }
}
.c-dropdown__clear-filters {
    font-size: 16px;
    line-height: 1;
    margin-left: auto;
    display: block;
    background-color: transparent;
    color: $environment-green;
    transition: color 0.2s ease;
    cursor: pointer;

    .c-icon {
        width: 10px;
        transition: fill 0.2s ease;
    }

    &:hover {
        color: $old-green;

        .c-icon {
            fill: $old-green !important;
        }
    }
}

.c-dropdown__sort {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $dropdown-chevron;
    position: absolute;
    right: 20px;
    bottom: 32px;
}

.c-dropdown__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    border: 1px solid $dropdown-border;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    background-color: $dropdown-bg;
    display: block;
    font-family: $main-font;
    font-weight: 300;
    height: 50px;
    line-height: 50px;
    padding: 0 35px 0 15px;
    position: relative;

    .o-filter-list & {
        font-size: rem(22);
    }

    .c-dropdown--disabled & {
        color: $dropdown-disabled;
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $dropdown-chevron;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        .c-dropdown--disabled & {
            border-top: 5px solid $dropdown-disabled;
        }
    }
}

.c-dropdown__list {
    width: 100%;
    position: absolute;
    border: 1px solid $dropdown-border;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
    background-color: $dropdown-bg;
    border-top: none;
    margin-top: -13px;
    padding: 16px 0;
    z-index: 1;

    .c-checkbox__label {
        padding-left: 40px;
        padding-right: 15px;
        padding-top: 4px;
        padding-bottom: 4px;
        position: relative;

        &::before {
            position: absolute;
            left: 15px;
            top: 8px;
        }
    }
}