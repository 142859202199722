/**
 * COMPONENTS.SLIDER
 *
 * @description :
 *
 */

 .c-slider_module {
    overflow: hidden;
    padding-bottom: 54px;
    margin-left:18px;
    @include breakpoint($md){
        margin-left:auto;
    }
}

.c-slider_module__title {
    margin-bottom:rem(21);
    
    @include breakpoint($md) {
        margin-bottom:rem(24);
    }
    @include breakpoint($lg) {
        margin-bottom:rem(32);
    }
}

.c-slider_module__subtitle {
    font-weight: 300;
    margin-bottom:rem(32);
    @include breakpoint($md) {
        margin-bottom:rem(45);
    }
    @include breakpoint($lg) {
        margin-bottom:rem(60);
    }
}

.c-slider_module__inner {
    overflow: visible !important;  // Override Swiper plugin default
}

.c-slider_module__list {
    padding-bottom: rem(60);

    @include breakpoint($md) {
        padding-bottom: rem(120);
    }
}

.c-slider_module__item {
    margin-right: 28px;
    width:auto;
    display: inline-block;
}
.c-slider__btns {
    display: block;
    position: relative;
    text-align: right;
    margin-bottom: 28px;
}
.c-slider__btn {
    position: relative;
    display: inline-block;
    height: auto;
    padding:0 30px;
    margin-bottom: 28px;
    color: $environment-green;

    &:after {
        font-size:32px;  
    }
}
.c-slider__btn--prev {
    left:auto;
}
.c-slider__scrollbar {
    background: $light-grey;
    height: 1px !important;
    
    .swiper-scrollbar-drag {
        background: $light-grey;
        height: 10px;
        top: -4px;
    }
}

