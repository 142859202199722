.c-section-sport {
    background: linear-gradient(180deg, #006598 0%, #00355F 100%);
    color:$section-sport-color;
    padding-top:44px;
    padding-left:15px;
    padding-right:15px;
    
    @include breakpoint($md){
        padding-top:90px;
    }
    @include breakpoint($lg){
        padding-left:0px;
        padding-right:0px;
    }
}
.c-section-sport__header {
    margin-bottom: 32px;
}
.c-section-sport__title {
    margin-bottom: 20px;   
    color:$section-sport-color;
    
    @include breakpoint($md) {
        margin-bottom: 40px;   
    }
}