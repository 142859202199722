.c-menu {
    flex: 1;
    position: fixed;
    left: 0;
    height: 100%;
    background: $tasman-mako;
    z-index: $menu-z;
    visibility: hidden;
    transform: translateX(-100%);
    transition: transform 0.25s ease-out;
    top: 65px;
    margin-top: -4px;
    padding: 42px 20px 20px 20px;
    overflow: scroll;
    display: inline-grid;
    grid-template-columns: auto 60px;
    // justify-content: flex-end;

    @media (max-width: $lg) {
        width: 100%;
        display: block;
    }

    @include breakpoint($menu) {
        overflow: visible;
        padding: 0;
        transform: none;
        background: none;
        visibility: visible;
        position: static;
        margin-top: 0;
    }

    .c-header--menu-open & {
        transform: translateX(0%);
        visibility: visible;
    }
}
.c-menu__btn-container {
    text-align: right;
    @include breakpoint($menu){
        display: none;
    }
}
.c-menu__btn {
    height:52px;
    width:70px;
    padding:15px 15px 17px  15px;
    margin:-12px -15px -15px -15px;
    background: url('/Image/menu-btn-open.svg') no-repeat center;
    
    
    .c-header--menu-open & {
        background: url('/Image/menu-btn-close.svg') no-repeat center;
    }
}
//firefox
@-moz-document url-prefix() {
    .c-menu__btn {
        padding:30px 15px 17px  15px;
    }
}
.c-menu__list {
    position: relative;
    //flex: 0 0 auto;
    justify-content: flex-end;
    @include breakpoint($menu) {
        margin-bottom: 0;
        padding-bottom: 0;
        display: inline-grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-row: 1;
        border-bottom: none;
    }
}
.c-menu__item {
    //flex: 1 0 120px;
    //width: 0;
    padding-bottom: 18px;
    grid-row: 1;
    /*padding-left: 15px;
    padding-right: 15px;*/

    .c-menu__sub-items & {
        padding-bottom: 10px;
    }

    @include breakpoint($menu) {
        padding-bottom: 0px;
        text-align: center;
    }
}

.c-menu__search-button {
    /*display: inline-block;*/
    width: 30px;
    height: 30px;
    /*position: absolute;*/
    margin-left: 30px;
    /*margin-right: 25px;*/
    appearance: none;
    background-color: transparent;
    margin-top: 43px;
    cursor: pointer;
    transition: margin-top .5s;
    grid-row: 1;
    justify-self: end;

    @media (max-width: $lg) {
        margin-top: 0px;
        position: absolute;
        right: 55px;
    }
}


.c-header--scrolled .c-menu__search-button {
    //margin-top: 30px;
}

.c-menu__search-button svg {
    max-height: 100%;
}

.c-search-bar {
    transition: max-height .3s;
    max-height: 125px;
    overflow: hidden;
    background-color: #00355F;

    .c-header-wrapper > & {
        position: fixed ;
        width: 100%;
        z-index: 9999;
    }

    .c-header-wrapper--search-bar-hidden & {
        max-height: 0;
    }
    
    .c-header--scrolled + & {
        //position: sticky;
        //top: 60px;
        //z-index: 9;

        @include breakpoint($menu){
            //top: 96px;
        }
    }
    
    .c-large-input__bar {
        max-width: 710px;
        margin: 30px auto;
    }
}

.c-menu__indicator {
    display: none;

    @include breakpoint($menu) {
        display: inline-block;
        left: 0;
        height: .25rem;
        width: 20%;
        margin: 0;
        background: $old-green;
        border: none;
        transition: .3s ease-in-out;
        position: absolute;
        bottom: 0;

       

        .home & {
            opacity: 0;
        }

        .c-menu:hover & {
            //  opacity: 1;
        }
    }
}

  

.c-menu__item--selected {
    color: $old-green;
    @include breakpoint($md) {
        color: $white;
    }
}
.c-menu__sublink {
    color:$white;
    font-size: .625em;
    text-decoration: none;
    
    
    i {
        vertical-align: text-bottom;
        font-size:rem(21px);
        margin-right: 3px;
    }
}
.c-menu__link-wrap {
    @include breakpoint($menu) {
        padding-bottom: 33.5px;
        padding-top: 45px;
    }
}


.c-menu__link {
    color: $white;
    text-decoration: none;
    width: 100%;
    transition: padding 0.5s ease-out;
    white-space: nowrap;
    letter-spacing: normal;
    width: fit-content;

    .c-menu__sub-items & {
        font-size: rem(21px);
        max-width: 250px;
        white-space: break-spaces;
    }

    @include breakpoint($menu) {
        display: inline-block;
        //padding-bottom: 33.5px;

    }

    .c-header--scrolled & {
        @include breakpoint($menu) {
            //padding-top: 37.5px;
            //padding-bottom: 28px;
        }
    }

    @media (max-width: $lg) {
        display: inline-block;
    }
}

.c-menu__link-text {
    cursor: default;
    padding: 5px;
}

.c-menu__dropdown-link {
    background:none;
    position: absolute;
    right: 0;
    transition: transform 0.25s ease;
    .c-menu__sub-items & {
        right: 6px;
        bottom: 0;
    }
    @include breakpoint($menu) {
        display: none;
    }
    &:focus {
        outline: $tasman-mako;
    }
}
.c-menu__dropdown-link--active {
    // transform: rotate(180deg);
    // .c-menu__sub-items & {
    //     color: $environment-green;
    // }
}
.c-menu__dropdown-icon {
    color: $old-green;
    font-size:rem(48px);
    transform:rotate(270deg);
    
    .c-menu__sub-items & {
        color: $white;
        font-size:rem(32px);
    }
    .c-menu__link-back & {
        transform:rotate(90deg);
        margin-bottom: 24px;
        vertical-align: -6px;
    }

    @include breakpoint($menu) {
        display: none;
    }
}
.c-menu__link-back {
    color:$white;
    text-decoration: none;
    font-size: 0.9em;
}
.c-menu__sub-items {
    visibility: hidden;
    height:0;
    opacity: 0;
    transition: opacity 0.3s ease;
    
    @include breakpoint($menu) {
        display: none;
    }
}
.c-menu__sub-items--open {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top:0;
    background:$tasman-mako;
    width:100%;
    height:auto;
    z-index: $menu-submenus;

    .c-menu__sub-items & {
        height:auto;
        min-height:calc(100% + 150px);
    }
}
.c-menu__sublinks {
    margin-top: 16px;
    padding-top: 32px;
    border-top: 1px solid rgba(255,255,255,0.5);

    @include breakpoint($menu){
        display: none;
    }
}
.c-menu__link--parent {
    .c-menu__sub-items & {
        font-size: 1em;
        display: block;
        margin-bottom: 12px;
    }
}


.c-menu__sublevel {
    @include background-opacity($tasman-mako, 0.95);
    color: $white;
    position: fixed;
    left: 0;
    right: 0;
    top: 235px;
    padding-bottom: 3rem;
    text-align: left;
    transition: top .5s 0.1s;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s, top .3s;

    &.is-active {
        opacity: 1;
        pointer-events: unset;
    }

    .c-header--scrolled & {
        //transition-delay: -0.2s;
        //top: 97px
    }

    .c-header-wrapper--search-bar-hidden & {
        top: 110px;
    }
}

.c-menu__sublevel_inner {
    border-top: 1px solid $old-green;
    border-bottom: 1px solid $old-green;

    @include breakpoint($lg) {
        max-width: 90rem;
        margin: 0 auto;
        display: flex;
        margin-top: 1px;
        transition: margin-top 0 linear;
        transition-delay: 0s;
    }

    .c-header-wrapper--search-bar-hidden & {
        margin-top: 0px;
        transition-delay: 0.3s;
    }
}

.ng-p {
    padding-left: 1.16667%;
    padding-right: 1.16667%;
}

.c-menu__sublevel_content {
    @include breakpoint($lg) {
        flex: 0 1 80%;
        padding-right: 1.75rem;
    }
}

.c-menu__sublevel_title {
    font-size: 36px;
    line-height: 40px;
    margin: 3rem 0 0.5rem 0;
    text-transform: capitalize;
    font-weight: bold;

    a {
        color: $white;
        text-decoration: none;
    }
}

.c-menu__sublevel_subtitle {
    font-size: 19px;
    line-height: 24px;
    color: $white;
    margin-bottom: 50px;
    text-transform: none;
}

.c-menu__sublevel_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-column-gap: 1rem;
    margin-bottom: calc(3rem - 0.625rem);
}

.c-menu__sublevel_item {
    line-height: 16px;
    display: block;
    margin-bottom: 30px;

    a {
        font-size: 16px;
        line-height: 16px;
        color: $white;
        text-decoration: none;
        transition: color .2s;

        &:hover {
            color: $old-green;
        }
    }
}

.c-menu__features {
    text-decoration: none;
    padding-top: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 40%;
    flex: 0 1 40%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    //border-top: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    .c-menu__sublevel & {
        flex: 0 1 35%;
    }
}

.c-menu__feature {
    max-width: 328px;
    padding-bottom: 3rem;

    &:hover .c-menu__card_content {
        border-bottom-color: $environment-green;
    }

    .c-menu__sublevel & {
        max-width: 315px;
    }
}

.c-menu__card_image {
    width: 100%;
    position: relative;
    padding-top: 40%;
    overflow: hidden;

    img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    //max-width: 328px;
}

.c-menu__card_content {
    background: $white;
    border-bottom: 5px solid $old-green;
    padding: 30px;
    transition: border-bottom-color .2s;
    //max-width: 328px;
}

.c-menu__card_heading {
    color: $tasman-mako;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 10px;

    .c-menu__sublevel & {
        color: $black;
        text-transform: none;
    }
}

.c-menu__card_desc {
    color: $black;
    font-size: 16px;
}

.js-menu-dropdown {
    position: relative;
}