/**
 * COMPONENTS.SHARING
 *
 * @description :
 *
 */

.c-sharing_links {
    display: flex;
    align-items: center;
    font-size: rem(14);
    position: relative;
}

.c-sharing_links__label {
    display: none;
    position: absolute;
    font-size:1em;
    text-transform: uppercase;
    top:-20px;
    margin-left:31px;
    color:$sharing-links-label-colour;

    .c-sharing_links__group & {
        margin-left:15px;
    }

    .c-detail-list__share & {
        position: relative;
        font-size: .725em;
        top:0;
        margin-left:0;
        text-decoration: none;
        display: inline;

        &:hover {
            text-decoration: underline;
        }
    }
    
    @include breakpoint(60em) {
        display: inline;
    }
}
.c-sharing_links__label--wide {
    margin-left:42px;
    .c-sharing_links__group & {
        margin-left:42px;
    }
}
.c-sharing_links__group {
    display: flex;
}
.c-sharing_links__link {
    width:40px;
    text-decoration: none;

    &:not(:last-child) {
        margin-right: rem(16)
    }

    &:hover {
        .c-sharing_links__circle {
            stroke: $beta;
        }
    }

    .c-detail-list__share & {
        flex:1;
    }

    @include breakpoint($md) {
        width:52px;
    }
}
.c-sharing_links__link--print {
    display: none;
    @include breakpoint($md) {
        width: 90px;
        display: inline-block;
    }
}
.c-sharing_links__icon {
    height:52px;
    color: $lime-scooter;
}

.c-sharing_links__circle {
    transition: stroke 0.3s ease-in-out;
}
