.c-modal__content {
    margin:0 auto;
    transition:transform 0.35s ease-out;
    padding:16px 16px 42px 16px;
    background:$secondary-bg;
    left:0;
    right:0;
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    max-width:690px;

    p {
        color: $modal-color;
        line-height: 1.3;
        font-weight: 500;
    }
}
.c-modal--dismissed {
    opacity: 0;
    pointer-events: none;
}
.c-modal__close-btn {
    background: transparent;
    padding-top: 6px;

    &:hover {
        cursor: pointer;
    }
}
.c-modal__icon {
    height:62px;
    width:62px;
    display: none;
    margin-right:16px;
    vertical-align: top;
    margin-top: 6px;

    @include breakpoint($md){
        display: inline-block;
    }
}
.c-modal__copy {
    display: inline-block;
    width: calc(100% - 16px);
    font-weight: 100;
    margin-top:42px;

    @include breakpoint($md){
        width: calc(100% - 70px);
    }
}
.c-modal {
    z-index:  $modal-z;
    background:$modal-bg-mask;
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    
    display: none;
}
.c-modal--open {
    display: block;
}