/**
 * UTILITIES.COLOR
 *
 * @description : Foreground and background color classes.
 *
 * If there are special accent colors for each color you could also
 * add CSS custom properties for those here.
 */

@each $name, $value in $main-colors {
    .u-fg-#{$name} {
        --fg-color: #{$value} !important;
        color: $value !important;
        fill: $value !important;
    }

    .u-bg-#{$name} {
        --bg-color: #{$value} !important;
        background-color: $value !important;
    }
}