.c-card {
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
    text-decoration: none;
    transition: border-color 0.4s ease-out;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background: white;

    &:not(:hover) {
        .c-news__card-trim {
            background-color: $light-grey !important;
        }
    }

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
    }

    .c-slider_module__item & {
        display: inline-block;
        margin-bottom: 0;
        margin-top: 0;
        max-width: 330px;
        box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1);
    }

    &--related-link,
    &--tile {
        border-bottom: 4px solid $light-grey;

        & .c-card__title {
            &::before {
                display: block;
                content: '';
                height: 2px;
                width: 3em;
                background-color: $primary-blue;
                margin-top: 0.4em;
                margin-bottom: 0.7em;
            }
        }
    }
}
.c-card--tile {
    min-height: 205px;
    background-color:$primary-bg;
    @include breakpoint($md) {
        min-height: 225px;
    }
}
.c-card--news {
    margin:0 0 51px 0;

    .gi:last-child & {
        margin:0 0 32px;
        @include breakpoint($lg) {
            margin:0 0 51px;
        }
    }
}
.c-card--center {
    text-align: center;
}
.c-card--icon {
    .c-slider_module__item & {
        height:420px;
    }
}
.c-card__content {
    padding:16px 21px 28px 21px;
    background-color:$primary-bg;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.c-card__icon-bg {
    background-color: $card-icon-bg;
    padding: 28px 0;
    text-align: center;
    transition: background-color 0.4s ease;


    .c-card--economic-purple:hover & {
        background-color: $economy-purple;
    }

    .c-card--default-blue:hover & {
        background-color: $primary-blue;
    }

    .c-card--regulatory-red:hover & {
        background-color: $regulatory-red;
    }

    .c-card--environment-green:hover & {
        background-color: $environment-green;
    }

    .c-card--local-democracy-cyan:hover & {
        background-color: $local-democracy-cyan;
    }

    .c-card--community-services-teal:hover & {
        background-color: $community-teal;
    }

    .c-card--environmental-green:hover & {
        background-color: $environment-green;
    }

    .c-card--financial-management-green:hover & {
        background-color: $financial-green;
    }

    .c-card--infrastructure-orange:hover & {
        background-color: $infrastructure-orange;
    }

    .c-card--regulatory-warm-red:hover & {
        background-color: $regulatory-warm-red;
    }

    .c-card--sport-and-recreation-blue:hover & {
        background-color: $sport-blue;
    }

    .c-card--library-blue:hover & {
        background-color: $library-blue;
    }

    .c-card--economic-purple:hover & {
        background-color: $economy-purple;
    }

    .c-card--default-blue:hover & {
        background-color: $primary-blue;
    }

    .c-card--regulatory-red:hover & {
        background-color: $regulatory-red;
    }

    .c-card--environment-green:hover & {
        background-color: $environment-green;
    }

    .c-card--local-democracy-cyan:hover & {
        background-color: $local-democracy-cyan;
    }

    .c-card--community-services-teal:hover & {
        background-color: $community-teal;
    }

    .c-card--financial-management-green:hover & {
        background-color: $financial-green;
    }

    .c-card--infrastructure-orange:hover & {
        background-color: $infrastructure-orange;
    }

    .c-card--regulatory-warm-red:hover & {
        background-color: $regulatory-warm-red;
    }

    .c-card--sport-and-recreation-blue:hover & {
        background-color: $sport-blue;
    }

    .c-card--library-blue:hover & {
        background-color: $library-blue;
    }
    //Hex colors
    .c-card--8c275b:hover & {
        background-color: $economy-purple;
    }

    .c-card--00395b:hover & {
        background-color: $primary-blue;
    }

    .c-card--e21f26:hover & {
        background-color: $regulatory-red;
    }

    .c-card--40860e:hover & {
        background-color: $environment-green;
    }

    .c-card--00adee:hover & {
        background-color: $local-democracy-cyan;
    }

    .c-card--009f90:hover & {
        background-color: $community-teal;
    }

    .c-card--006937:hover & {
        background-color: $financial-green;
    }

    .c-card--f6931d:hover & {
        background-color: $infrastructure-orange;
    }

    .c-card--ef533f:hover & {
        background-color: $regulatory-warm-red;
    }

    .c-card--4485c6:hover & {
        background-color: $sport-blue;
    }

    .c-card--0170a4:hover & {
        background-color: $library-blue;
    }

    .c-card--8c275b:hover & {
        background-color: $economy-purple;
    }

    .c-card--00395b:hover & {
        background-color: $primary-blue;
    }

    .c-card--e21f26:hover & {
        background-color: $regulatory-red;
    }

    .c-card--40860e:hover & {
        background-color: $environment-green;
    }

    .c-card--00adee:hover & {
        background-color: $local-democracy-cyan;
    }

    .c-card--009f90:hover & {
        background-color: $community-teal;
    }

    .c-card--006937:hover & {
        background-color: $financial-green;
    }

    .c-card--f6931d:hover & {
        background-color: $infrastructure-orange;
    }

    .c-card--ef533f:hover & {
        background-color: $regulatory-warm-red;
    }

    .c-card--4485c6:hover & {
        background-color: $sport-blue;
    }

    .c-card--0170a4:hover & {
        background-color: $library-blue;
    }
}
.c-card__icon {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.4s ease;

    .c-card:not(.c-card-alt):hover &:not(.c-card__icon--hover) {
        opacity: 0;
    }
}
.c-card__icon--hover {
    position: absolute;
    margin:0 auto;
    left: 0;
    opacity: 0;
    right: 0;
    transition: opacity 0.4s ease;

    .c-card:hover & {
        opacity: 1;
    }
}

.c-card__title {
    font-weight: bold;
    line-height: 23px;
    font-family: $secondary-font;

    &::after {
        display: block;
        content: '';
        height: 2px;
        width: 3em;
        margin-top: 0.5em;

        .c-card--center & {
            margin: 0.5em auto 0 auto;
        }
    }

    .c-card--news &, .c-card--tile & {
        font-size: 19px;

        &:after {
            display: none;
        }
        /*&::before {
            display: block;
            content: '';
            height: 2px;
            width: 3em;
            background-color: $primary-blue;
            margin-top: 0.4em;
            margin-bottom: 0.7em;
        }*/
    }

    &::after, &::before {
        background-color: $primary-blue;

        .c-card--economic-purple & {
            background: $economy-purple;
        }

        .c-card--default-blue & {
            background: $primary-blue;
        }

        .c-card--regulatory-red & {
            background-color: $regulatory-red;
        }

        .c-card--environment-green & {
            background-color: $environment-green;
        }

        .c-card--local-democracy-cyan & {
            background-color: $local-democracy-cyan;
        }

        .c-card--community-services-teal & {
            background-color: $community-teal;
        }

        .c-card--financial-management-green & {
            background-color: $financial-green;
        }

        .c-card--infrastructure-orange & {
            background-color: $infrastructure-orange;
        }

        .c-card--regulatory-warm-red & {
            background-color: $regulatory-warm-red;
        }

        .c-card--sport-and-recreation-blue & {
            background-color: $sport-blue;
        }

        .c-card--library-blue & {
            background-color: $library-blue;
        }
        //Hex colors
        .c-card--8c275b & {
            background: $economy-purple;
        }

        .c-card--00395b & {
            background: $primary-blue;
        }

        .c-card--regulatory-red & {
            background-color: $regulatory-red;
        }

        .c-card--40860e & {
            background-color: $environment-green;
        }

        .c-card--00adee & {
            background-color: $local-democracy-cyan;
        }

        .c-card--009f90 & {
            background-color: $community-teal;
        }

        .c-card--006937 & {
            background-color: $financial-green;
        }

        .c-card--f6931d & {
            background-color: $infrastructure-orange;
        }

        .c-card--ef533f & {
            background-color: $regulatory-warm-red;
        }

        .c-card--4485c6 & {
            background-color: $sport-blue;
        }

        .c-card--0170a4 & {
            background-color: $library-blue;
        }
    }
}
.c-card__subheading {
    color: $concrete;
    font-family: $secondary-font;
    font-size: rem(16px);
    font-weight: 400;
    text-transform: uppercase;
}
.c-card__subtitle {
    font-size:14px;
    font-weight: 400;
    margin-top:17px;
    color: $card-link-color;
}
.c-card__copy {
    font-size:0.81em;
    margin-top:18px;
    color: $black;
    min-height: 40px;
    flex: 1;

    .c-card--icon {
        overflow: hidden;
    }
    .c-card--news & {
        font-size:rem(21);
    }
    .c-card--newsletter & {
        p {
            font-size:rem(21);
            margin-bottom: 8px;
        }
        ul {
            list-style:inside;
            margin-bottom: 8px;
        }
        li {
            font-size:rem(21);
            font-weight: 100;
        }
    }
}
.c-card__date {
    font-size: 15px;
    line-height: 21px;
    font-family: $secondary-font;
    font-weight: 500;
    text-transform: uppercase;
    color: $card-link-color;
    margin: 0;
}
.c-card__image {
    width:100%;

    &.c-card__image--placeholder {
        height:138px;
        padding:10px;
    }

    &.c-card__image--external {
        height:196px;
        padding:28px 0;
    }
    .c-card--newsletter & {
        height: 140px;
        object-fit: cover;
    }
    .c-card--news & {
        max-height:170px;

        @include breakpoint($sm){
            max-height:140px;
        }
    }
}
.c-card__image--fit {
    object-fit: cover;
}
.c-card__type-icon {
    margin-left:auto;
    width:32px;
    height:32px;
    display: none;
    margin-left:auto;

    @include breakpoint($md){
        display: block;
    }
}



/* Alt card styles */

.c-card-alt {
    background: transparent;
    box-shadow: none !important;
    border-bottom: none;

    &:hover {
        box-shadow: none !important;
    }

    .c-card__content {
        padding: 1rem 0 0 0 !important;
        background-color: transparent !important;
    }

    .c-card__title {
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        transition: color .2s;
        margin-bottom: 0rem;

        &::after {
            margin-top: 1.5rem;
        }
    }

    .c-card__subtitle {
        margin-bottom: 1rem;
        font-size: 13px;
        line-height: 16px;
        color: #808080;
    }

    .c-card__copy {
        font-size: 16px;
        line-height: 25px;
    }

    .c-btn__faux-link {
        font-size: 15px;
        line-height: 21px;
        color: #808080;
        transition: color .2s;
    }
}



.c-card--economic-purple:hover .c-card__title,
.c-card--economic-purple:hover .c-btn__faux-link {
    color: $economy-purple;
}

.c-card--default-blue:hover .c-card__title,
.c-card--default-blue:hover .c-btn__faux-link {
    color: $primary-blue;
}

.c-card--regulatory-red:hover .c-card__title,
.c-card--regulatory-red:hover .c-btn__faux-link {
    color: $regulatory-red;
}

.c-card--environment-green:hover .c-card__title,
.c-card--environment-green:hover .c-btn__faux-link {
    color: $environment-green;
}

.c-card--local-democracy-cyan:hover .c-card__title,
.c-card--local-democracy-cyan:hover .c-btn__faux-link {
    color: $local-democracy-cyan;
}

.c-card--community-services-teal:hover .c-card__title,
.c-card--community-services-teal:hover .c-btn__faux-link {
    color: $community-teal;
}

.c-card--financial-management-green:hover .c-card__title,
.c-card--financial-management-green:hover .c-btn__faux-link {
    color: $financial-green;
}

.c-card--infrastructure-orange:hover .c-card__title,
.c-card--infrastructure-orange:hover .c-btn__faux-link {
    color: $infrastructure-orange;
}

.c-card--regulatory-warm-red:hover .c-card__title,
.c-card--regulatory-warm-red:hover .c-btn__faux-link {
    color: $regulatory-warm-red;
}

.c-card--sport-and-recreation-blue:hover .c-card__title,
.c-card--sport-and-recreation-blue:hover .c-btn__faux-link {
    color: $sport-blue;
}

.c-card--library-blue:hover .c-card__title,
.c-card--library-blue:hover .c-btn__faux-link {
    color: $library-blue;
}

//Hex colors
.c-card--8c275b:hover .c-card__title,
.c-card--8c275b:hover .c-btn__faux-link {
    color: $economy-purple;
}

.c-card--00395b:hover .c-card__title,
.c-card--00395b:hover .c-btn__faux-link {
    color: $primary-blue;
}

.c-card--e21f26:hover .c-card__title,
.c-card--e21f26:hover .c-btn__faux-link {
    color: $regulatory-red;
}

.c-card--40860e:hover .c-card__title,
.c-card--40860e:hover .c-btn__faux-link {
    color: $environment-green;
}

.c-card--00adee:hover .c-card__title,
.c-card--00adee:hover .c-btn__faux-link {
    color: $local-democracy-cyan;
}

.c-card--009f90:hover .c-card__title,
.c-card--009f90:hover .c-btn__faux-link {
    color: $community-teal;
}

.c-card--006937:hover .c-card__title,
.c-card--006937:hover .c-btn__faux-link {
    color: $financial-green;
}

.c-card--f6931d:hover .c-card__title,
.c-card--f6931d:hover .c-btn__faux-link {
    color: $infrastructure-orange;
}

.c-card--ef533f:hover .c-card__title,
.c-card--ef533f:hover .c-btn__faux-link {
    color: $regulatory-warm-red;
}

.c-card--4485c6:hover .c-card__title,
.c-card--4485c6:hover .c-btn__faux-link {
    color: $sport-blue;
}

.c-card--0170a4:hover .c-card__title,
.c-card--0170a4:hover .c-btn__faux-link {
    color: $library-blue;
}

.c-card-grid {
    display: grid;
    @media (min-width: $md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.c-card-wrapper {
    margin-inline: 8px;
    padding-bottom: 51px;

    @media (min-width: $lg) {
        margin-inline: 16px;
    }
}
