.c-related-content {
    border-top:1px solid $related-content-border;
    padding:28px 0 38px;
    margin-top:30px;
    
    @include breakpoint($md){
        padding:42px 0 40px;
        margin-top:50px;
    }
}
.c-related-content__title {
    color:$primary-blue;
    margin-bottom: 28px;
    
    @include breakpoint($md) {
        margin-bottom: 40px;
    }
}