.c-cost-table {
    th, td {
        padding: 1rem;
    }
}

.c-cost-table__cell {
}

.c-cost-table__row {
    &:last-child {
        border-bottom: none;
    }
}
