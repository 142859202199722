﻿.c-signup-tag {
    display: block;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 #D8D8D8;
    background: #fff;
    width: 100%;
    overflow: hidden;
    text-align: left;
    z-index: 5;
    position: relative;
    cursor: pointer;


    img {
        max-height: 32px;
        margin-left: 20px;
        margin-right: 20px;
    }

    &__text {
        align-self: stretch;
        padding: 22px 0 22px 23px;
        border-left: 1px solid #D8D8D8;
        font-size: 16px;
        line-height: 20px;
    }

    input {
        /*margin: 17px 24px 17px auto;
        width: 30px;
        height: 30px;*/
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 17px;
        right: 24px;
        height: 30px;
        width: 30px;
        background-color: none;
        border: 1px solid rgba($environment-green, 1);
        border-radius: 4px;
        //transition: all 0s ease;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 10px;
            top: 6px;
            width: 8px;
            height: 15px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover input:not(:checked) ~ .checkmark {
        border: 1px solid rgba($lime-scooter, 1);
    }

    input:checked ~ .checkmark {
        background-color: $environment-green;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
}
