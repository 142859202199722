.c-error {
    margin-top:60px;
    margin-bottom:42px;
    padding:0 20px;
    
    @include breakpoint($md){
        margin-top:160px;
        margin-bottom:90px;
    }
    
    @include breakpoint($lg){
        padding:0;
    }
}