/**
 * COMPONENTS.FOOTER
 *
 * @description : Main site footer
 *
 */

.c-footer {
    color: $white;
    background: $primary-blue;
    position: relative;
    padding: 0px 20px 0 20px;
    
    @include breakpoint($sm) {
        padding: 0 20px 0px 20px;
    }
    @include breakpoint($xl) {
        padding: 0 0 0;
    }
}
.c-footer__wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: $md) {
        justify-content: flex-start;
        //align-items: flex-start;
        flex-direction: column;
    }
}
.c-footer__item {
    margin-right: 0;
    margin-bottom: 1.5rem;

    @include breakpoint($sm) {
        margin-right: 3rem;
        margin-bottom: 0;
    }

    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    @media (max-width: $lg) {
        flex-grow: 1;
    }
}
.c-footer--scrolled-to {
    margin-top: -62px;
}
.c-footer__list {
    display: flex;
}
.c-footer__list-item {
    margin-bottom: 28px;

    @include breakpoint($lg) {
        margin-bottom: 0px;
    }
}
.c-footer__sublist {
    .c-footer__list--double-col & {
        @include breakpoint($sm){
            columns: 2;
        }
    }
}
.c-footer__sublist-item {
    line-height: 1.6;
}
.c-footer__link {
    color: $white;
    text-decoration: none;
    font-size:.625em;
    display: block;
    margin-bottom: 10px;
}
.c-footer__link--primary {
    font-size:.8em;
    margin-bottom: 16px;
    display: block;
    font-weight: bold;
}
.c-footer__list-title {
    color: $white;
    font-size: .8em;
    line-height: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    text-transform: none;
    font-family: $main-font;
    letter-spacing: normal;
}
.c-footer__logos {
    padding-top: 0;
    padding-bottom: 28px;
    
    @include breakpoint($sm) {
        padding-bottom: 52px;
        padding-top: 0;
    }
    @include breakpoint($lg) {
        padding-top: 0;
    }

    &:before {
        content:'';
        display:block;
        //background: rgba(255,255,255,0.5);
        height:1px;
        width:100%;
        max-width: 1400px;
        margin:0 auto;
        margin-bottom: 28px;
        @include breakpoint($sm) {
            margin-bottom: 52px;
        }
    }
}
.c-footer__logo {
    //text-align: center;
    @include breakpoint($sm) {
        text-align: left;
    }
}
.c-footer__logo--right {
    //text-align: center;
    margin-top: 0;
    margin-left: 0;

    @include breakpoint($sm) {
        margin-top: 0;
        text-align: right;
        margin-left: auto;
    }
}
.c-footer__logo-image {
    display: inline-block;
    max-width: 240px;
    @include breakpoint($md) {
        max-width: none;
    }
}
.c-footer__details {
    background: $dark-blue;
    padding:20px 20px 50px;
    font-size:.675em;
    margin:0 -20px;
    
    p {
        font-size:1em;
        margin:0;
        display: inline-block;
    }
    
    a {
        // font-size:.675em;
        color: $white;
        text-decoration: none;
        margin-bottom:8px;
        display: block;
        
        @include breakpoint($md) {
            margin-bottom:0;
            margin-right:28px;
            display: inline-block;
        }
        
        &:first-child {
            margin-left:0px;
        }
    }

    @include breakpoint($sm) {
        padding:32px;
    }

    @include breakpoint($xl){
        margin:0;
    }
}
.c-footer__copyright {
    position: absolute;
    bottom:-30px;
    left:0;
    @include breakpoint($sm) {
        bottom:auto;
        position: relative;
    }
    @include breakpoint($md) {
        margin-right:40px;
    }
}
.c-footer__detail-links {
    margin-bottom:40px;
    margin-top:8px;

    @include breakpoint($sm) {
        margin-bottom:0px;
    }
}
.c-footer__nv {
    height: 36px;
    width: 36px;
    background: $black;
    border-radius: 50%;
    display: inline-block;
    padding: 7px;
    vertical-align: -12px;
    margin-left:5px;
}
.c-footer__nv-text {
    color:rgba(255,255,255,0.7)
}
.c-footer__nv-logo {
    margin-top:6px;
    margin-left:1px;
}
.c-footer__bordered {
    border-bottom: 1px solid rgba(255,255,255,0.5);
    border-right: none;

    @include breakpoint($sm) {
        border-right: 1px solid rgba(255,255,255,0.5);
        border-bottom: none;
    }
}
.c-footer__social-links {
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 0px;

    @include breakpoint($xl) {
        display: block;
    }
}
.c-footer__social-link {
    color: $white;
    display: inline-block;
    font-size: 0.65em;
    text-decoration: none;
    margin-bottom: 10px;
    flex: 1;
    max-width: 70px;

    @include breakpoint($xl) {
        margin-right: 33px;
    }

    @media (max-width: $lg) {
        max-width: 45px;
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        font-size: 2.2em;
        margin-right: 5px;
    }

    &:last-child {
        margin-right: 0;
    }
}
.c-footer__social-link--image {
    //height: 30px;
    width: 30px;
    max-width: 30px;
    color: white;
    //display: block;
}
.c-footer__social-link--text {
    max-width: none;
    letter-spacing: normal;
    //display: block;
}