.c-accordion--table {
    @include breakpoint($md){
        display: none;
    }
}
.c-accordion__item {
    margin-bottom: 6px;

    
}
.c-accordion__btn {
    display: block;
    border: 1px solid $accordion-border;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 $accordion-box-shadow;
    background: $white;
    width:100%;
    overflow: hidden;
    text-align: left;
    z-index:  $accordion-btn-z;
    position: relative;

    &:hover {
        cursor: pointer;
    }
}
.c-accordion__btn-icon {
    display: inline-block;
    width: 84px;
    height:100%;
    position: absolute;
    top:0;
    right:0;

    &:after {
        content:"";
        position: absolute;
        height:40px;
        width:40px;
        display: block;
        background:url("/Image/icons/circle-arrow-down.svg") no-repeat;
        margin:0 auto;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.35s ease, background 0.35s ease;
        
        .c-accordion__item--open & {
            transform: translateY(-50%) rotate(180deg);
            background:url("/Image/icons/circle-arrow-down-white.svg") no-repeat;
        }
    }
    .c-accordion__item--open & {
        background: $accordion-btn-active;
    }
}
.c-accordion__title {
    display: inline-block;
    padding: 18px 0 18px 16px;
    width: calc(100% - 84px);
    color: $accordion-title-color;
    font-size: 1.675em;
    font-weight: 300;
    margin-bottom: 0;

    @include breakpoint($md) {
        padding: 18px 0 18px 23px;
        font-weight: 500;
        font-size: 1.875em;
    }

    .c-accordion__item--coloured & {
        padding: 18px 0 18px 63px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 80px solid transparent;
            border-left: 70px solid $primary-blue;
            border-bottom: 80px solid transparent;
            position: absolute;
            left: -22px;
            transform: translateY(-63px);
        }
    }

    .c-accordion__item--red & {
        &:before {
            border-left-color: $regular-red;
        }
    }

    .c-accordion__item--green & {
        &:before {
            border-left-color: $regular-green;
        }
    }

    .c-accordion__item--f9423a & {
        &:before {
            border-left-color: $regular-red;
        }
    }

    .c-accordion__item--40860e & {
        &:before {
            border-left-color: $regular-green;
        }
    }

    .c-accordion__item--8c275b & {
        &:before {
            border-left-color: $economy-purple;
        }
    }

    .c-accordion__item--e21f26 & {
        &:before {
            border-left-color: $regulatory-red;
        }
    }

    .c-accordion__item--ef533f & {
        &:before {
            border-left-color: $regulatory-warm-red;
        }
    }

    .c-accordion__item--f6931d & {
        &:before {
            border-left-color: $infrastructure-orange;
        }
    }

    .c-accordion__item--009f90 & {
        &:before {
            border-left-color: $community-teal;
        }
    }

    .c-accordion__item--a2c037 & {
        &:before {
            border-left-color: #a2c037;
        }
    }

    .c-accordion__item--006937 & {
        &:before {
            border-left-color: $financial-green;
        }
    }

    .c-accordion__item--00adee & {
        &:before {
            border-left-color: $local-democracy-cyan;
        }
    }

    .c-accordion__item--4485c6 & {
        &:before {
            border-left-color: $sport-blue;
        }
    }

    .c-accordion__item--0170a4 & {
        &:before {
            border-left-color: $library-blue;
        }
    }

    .c-accordion__item--00395b & {
        &:before {
            border-left-color: $primary-blue;
        }
    }
}
.c-accordion__close {
    &:hover {
        cursor: pointer;
    }
}
.c-accordion__content {
    background:$white;
    position: relative;
    padding:35px 23px 23px 23px;
    z-index: $accordion-content-z;

    .c-accordion--table & {
        padding:0;
        margin-top:3px;
        margin-bottom:12px;
    }
}

.c-accordion__table-cell-content {
    box-shadow: 0 0 4px 0 $accordion-box-shadow;
    padding: 16px;

    p {
        margin-bottom: .75em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.c-accordion__table-cell-label {
    font-size: .75em;
    font-weight: bold;
}