﻿/**
 * TYPOGRAPHY.LISTS
 *
 * @description : List styles
 *
 */

/* e.g.
    .t-ulist {
        li  {
            margin-bottom: 1em;
        }
    }

    .t-olist {
        li  {
            margin-bottom: 1em;
        }
    }
*/