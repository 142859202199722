.c-contacts {
    flex-wrap: wrap;
    display: flex;
    
    @include breakpoint($sm){
    }
}
.c-contact {
    text-align: center;
    flex:1;
    padding:0.6em 1.5em;
    width:100%;
    min-width:220px;
    max-width: 612px;
    
    &:first-child {
        margin-bottom:30px;
    }

    @include breakpoint($sm){
        width:auto;

        &:first-child {
            margin-right:30px;
            margin-bottom:0;
        }
    }
    
    @include breakpoint($md){
        &:first-child {
        margin-right:110px;
        }   
    }
}
.c-contact__detail {
}
.c-contact__poc {
    display: block;

    @include breakpoint($md) {
        font-size:1.45em;
        display: block;
    }
}