// Set dynamic height for Iframe

.o-iframe {
    --iframe-height: rem(0);
    width: 100%;
    position: relative;

    &::before {
        content: "";
        display: block;
        position: relative;
        padding-top: var(--iframe-height);
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
