﻿.c-related-news {
    .c-news__card-heading {
        font-size: 19px;
        line-height: 23px;
    }

    .c-news__card-content {
        padding: 18px 30px 50px 30px;
    }

    .c-news__card-image {
        padding-top: 50%;
    }

    .c-news__card-separator {
        margin: 0.7rem 0 1rem 0 !important;
    }

    .c-news__card-date {
        font-family: "Helvetica Neue";
        font-weight: normal;
    }
}

.c-related-news__link {
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    transition: color 0.2s ease;

    .c-icon {
        width: 13px;
        transition: fill 0.2s ease;
    }

    &:hover {
        color: $old-green;

        .c-icon {
            fill: $old-green !important;
        }
    }
}
