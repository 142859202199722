.c-filter-header {
    display: flex;
    flex-flow: row wrap;
    gap: rem(20px);
}

.c-filter-header__heading {
    @extend .t-title_4;
    color: $primary-blue;
    flex: 1 1 auto;
}

.c-filter-header__results-count {
    flex: 0 0 auto;
    margin-left: auto;
}