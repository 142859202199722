.o-filter-list {
    position: static;
    z-index: $sticky-filters-z;
    background: $primary-bg;
    opacity: 1;
    transition: opacity 0.1s ease;
    box-shadow: 0px -18px 0px 18px $primary-bg;
}
.o-filters-list__heading {
    margin-bottom: 16px;
}
.o-filter-list--scrolled {
    --width: 100%;
    position: fixed;
    top: 76px;
    width: var(--width);

    .o-filters-list__heading {
        font-size: 18px;
        margin-bottom: 8px;
    }
}
.o-filter-list--hidden {
    opacity: 0;
    pointer-events: none;
}
.o-filter-list__container {
    @include breakpoint($md) {
        height: 256px;
    }

    &--auto {
        height: auto;
        margin-bottom: 64px;

        @include breakpoint($md) {
            margin-bottom: 64px;
        }
    }
}
.o-filter-list__filters {
    background: $secondary-bg;
    padding: 20px 18px;
}
.o-filter-list__filters--grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    &--order-1 {
        order: 1;
    }

    &--order-2 {
        order: 2;
    }

    &--order-3 {
        order: 3;
    }

    &--order-4 {
        order: 4;
    }

    &--order-5 {
        order: 5;
    }

    &--order-6 {
        order: 6;
    }

    @include breakpoint($md) {
        grid-template-columns: 1fr 1fr 1.4fr 1.4fr;

        &--order-1,
        &--order-2,
        &--order-3,
        &--order-4,
        &--order-5,
        &--order-6 {
            order: initial;
        }
    }
}