/**
 * SETTINGS.ZINDEX
 *
 * @description : 
 *
 */
 $layer-hidden: -1;
 $layer0: 1;
 $layer1: 5;
 $layer2: 10;
 $layer3: 50;
 $layer4: 100;
 $layer5: 200;
 $layer6: 400;
 $layer7: 500;
 $layer8: 1000;
 $layer9: 2000;
 $layer10: 4000;
 $layer11: 5000;
 $layer12: 9999;

 $menu-submenus:$layer0;
 $carousel-pagination:$layer0;
 $sticky-filters-z:$layer0;
 $accordion-content-z:$layer0;
 $accordion-btn-z:$layer1;
 $back-to-top-z:$layer2;
 $menu-z:$layer4;
 $header-z:$layer5;
 $modal-z:$layer8;